import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useReactRouter from 'use-react-router';

import {
  getAllMembershipRequestOfGroup,
  approveGroupMembershipRequestForUser,
  rejectGroupMembershipRequestForUser
} from 'library/common/commonActions/groupActions';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import Input from 'library/common/commonComponents/Inputs/Input';
import Loader from 'library/common/commonComponents/Loader';
import store from 'main/store/configureStore';

import MembershipRequestOverviewTable from './MembershipRequestOverviewTable';
import styles from './membershipRequestFrame.module.scss';

const MembershipRequestFrame = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [membershipRequestList, setMembershipRequestList] = useState([]);

  const [selectedMembershipRequests, setSelectedMembershipRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { match } = useReactRouter();
  const groupId = match.params.id;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const res = await getAllMembershipRequestOfGroup(groupId);
    setMembershipRequestList(res.data);
    setIsLoading(false);
  }, [groupId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filterMembershipRequests = filterValue => {
    setFilteredData(
      membershipRequestList.filter(request => {
        const { userName, groupName, requestStatus } = request;

        const s = filterValue.toLowerCase();

        return (
          userName.toLowerCase().includes(s) ||
          groupName.toLowerCase().includes(s) ||
          requestStatus.toString().includes(s)
        );
      }),
    );
  };

  const onChangeSearch = e => {
    filterMembershipRequests(e.target.value);
    setSearchValue(e.target.value);
  };

  const handleSingleApprove = (membershipRequest) => {
    approveGroupMembershipRequestForUser(membershipRequest.groupsId, membershipRequest.userId).then(() => {
      store.dispatch(
        showBottomNotification(t('Accept membership request!')),
      );
      fetchData();
    });
  }

  const handleSingleReject = (membershipRequest) => {
    rejectGroupMembershipRequestForUser(membershipRequest.groupsId, membershipRequest.userId).then(() => {
      store.dispatch(
        showBottomNotification(t('Reject membership request!'), {
          isWarning: true,
        }),
      );
      fetchData();
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div className={styles.filtersSearch}>
          <Input
            value={searchValue}
            onChange={onChangeSearch}
            className={styles.filtersSearchInput}
            placeholder={t('Administration.MembershipRequest.Input placeholder')}
          />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <MembershipRequestOverviewTable
          isLoading={isLoading}
          membershipRequests={searchValue.length === 0 ? membershipRequestList : filteredData}
          selectedMembershipRequests={selectedMembershipRequests}
          setSelectedMembershipRequests={setSelectedMembershipRequests}
          handleSingleApprove={handleSingleApprove}
          handleSingleReject={handleSingleReject}
        />
      )}
    </div>
  );
}

export default MembershipRequestFrame;
