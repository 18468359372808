import React, { useState, useRef, useEffect } from "react";
import styles from './fourthArticle.module.scss'
import { KitaPanelWrapper } from "library/common/commonComponents/KitaPanelWrapper";
import { Wrapper } from "library/common/commonComponents/Wrapper";
import Button from "library/common/commonComponents/Buttons/Button";
import TextArea from "library/common/commonComponents/Inputs/TextArea";
import shortid from "shortid";
import Popup from "library/common/commonComponents/Popups/Popup";
import { useTranslation } from "react-i18next";
import { saveArticle } from "library/api/kita";
import store from 'main/store/configureStore';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import useReactRouter from 'use-react-router';
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { isMatchesToMediaTypeArray } from "library/utilities/files";
import { deleteArticle, deleteArticleImage, getArticle, updateArticle, uploadImagesToArticle } from "library/api/articles";
import { connect } from 'react-redux';
import useEditor from "library/common/commonHooks/useEditor";
import { checkLink } from "library/utilities/checkLink";
import { sanitize } from "dompurify";

import Loader from 'library/common/commonComponents/Loader';


const FourthArticle = ({ activeKita, user }) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [deletePopup, setDeletePopup] = useState(false)
    const [deleteID, setDeleteID] = useState(null);
    const [articleDescription, setArticleDescription] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShowMode, setIsShowMode] = useState(true)
    const [selectedImages, setSelectedImages] = useState(null);
    const [articleData, setArticleData] = useState(null);
    const [carouselImages, setCarouselImages] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [noArticle, setNoArticle] = useState(false);
    const [articleId, setArticleId] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const { location, history, match } = useReactRouter();
    const kitaId = match.params.id;

  useEffect(() => {
        retrieveData();
    }, [])

    const retrieveData = () => {
        setIsLoading(true);
        getArticle(kitaId, 4).then((res) => {
            setArticleData(res.data);
            let images = []
            if (res.data) {
                setUpdateFlag(true);
                setArticleDescription(res.data.descriptionText)
                setArticleId(res.data.id);
                if (res?.data?.images) {
                    res.data.images.forEach((item) => {
                        images.push(item.filePath);
                    })
                    setSelectedFiles(res.data.images);
                    setSelectedImages(res.data.images);
                }
                setCarouselImages(images);
                setIsShowMode(true);
                setIsEditMode(false);
                setNoArticle(false);
                setIsLoading(false);
            } else {
                setEditor(true)
                setNoArticle(true)
                setIsLoading(false);
            }
        }).catch((item) => {
            setNoArticle(true)
            setEditor(true)
            setIsLoading(false);
        })
    }

    const filesizes = (bytes, decimals = 2) => {
        // ... Same logic as before ...
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i];
    };

    const handleChange = (event) => {
        const images = [...event.target.files];

        const noOfImages = selectedImages?.length + images?.length
        const noOfFiles = selectedFiles?.length + images?.length

        if (noOfFiles > 5 || noOfImages > 5) {
            store.dispatch(
                showBottomNotification(t('Article.More than 5 images not allowed'), {
                    isFail: true,
                }))
        } else {

            if (selectedImages) {
                setSelectedImages((previousImages) => [
                    ...previousImages,
                    images.length > 1 ? images : images[0]
                ]);
            } else {
                setSelectedImages(images)
            }
            // Spread operator for modern approach

            setSelectedFiles((prevValue) => [
                ...prevValue,
                ...images.map((file) => ({
                    id: shortid.generate(),
                    fileId: file.name,
                    filetype: file.type,
                    filePath: null, // Initially set to null
                    fileDescription: null,
                    //datetime: file.lastModifiedDate.toLocaleString("en-IN"),
                    filesize: filesizes(file.size),
                })),
            ]);

            // Create a closure over the file for each reader to ensure you update the correct one.
            images.forEach((file) => {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                    const base64Image = e.target.result; // Get the base64 image data

                    // Update the state with the base64 image for the corresponding file
                    setSelectedFiles((prevFiles) =>
                        prevFiles.map((f) =>
                            f.fileId === file.name ? { ...f, filePath: base64Image } : f
                        )
                    );
                };
                reader.readAsDataURL(file); // Read file as a data URL to get the base64 image
            });
        }
    };

    const handleDeleteSelectFile = (id, index) => {
        setDeletePopup(true);
        setDeleteID(id);
        setDeleteIndex(index);
    };

    const deleteItem = (id, deleteIndex) => {
        if (selectedFiles && selectedFiles.length > 0) {
            setSelectedFiles(selectedFiles.filter((data) => data.id !== id));
        }
        if (selectedImages && selectedImages.length > 0) {
            setSelectedImages(selectedImages.filter((data, index) => index !== deleteIndex));
        }

        setIsLoading(true);
        deleteArticleImage(id).then((res) => {
            store.dispatch(
                showBottomNotification(t('Article.Successful Deleted'), {
                    isFail: false,
                }))
                setIsLoading(false);
        }).catch((res) => {setIsLoading(false)})
        setDeletePopup(false);
    }

    const handleFileUploadSubmit = async (event) => {
        event.preventDefault();
        event.target.reset(); // Reset form on submit

        if (selectedFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
            setSelectedFiles([]);
        } else {
            alert("Please select a file");
        }
    };

    const submitArticle = () => {
        if (checksBeforeUploading()) {
        setIsLoading(true);
            const articleDesc = getEditorValue();
            if (!updateFlag) {
                uploadImagesToArticle(4, selectedImages, kitaId, checkLink(articleDesc)).then((res) => {
                    store.dispatch(
                        showBottomNotification(t('Article.Successful Saved'), {
                            isFail: false,
                        }))
                    setEditor(false)
                    retrieveData();
                setIsLoading(false);
                }).catch((res) => {setIsLoading(false)})
            } else {
                const filteredItems = selectedImages.filter(item => !('id' in item));
                updateArticle(articleId, checkLink(articleDesc), filteredItems[0]).then((res) => {
                    store.dispatch(
                        showBottomNotification(t('Article.Successful Updated'), {
                            isFail: false,
                        }))
                    setEditor(false)
                    retrieveData();
                setIsLoading(false);
                }).catch((res) => {setIsLoading(false)})
            }
        }
    }


    const checksBeforeUploading = () => {
        let showExtensionError = false;
        let sizeError = '';
        let maxFileSize = 200;
        const allowedExtensions = ['image/*', 'image/*'];
        let check = true;
        if (updateFlag) {
            let files = selectedImages
        } else {
            let files = selectedFiles
        }
        const allowedFiles = Array.from(files)
            .filter(file => {
                if (isMatchesToMediaTypeArray(file.filetype, allowedExtensions, file.filename)) {
                } else {
                    showExtensionError = true;
                    check = false
                }
            })
            .filter(file => {

                if (maxFileSize) {
                    if (file.size / 1024 / 1024 >= maxFileSize) {
                        sizeError = "FileManager.You can't upload file size more than {FILE_SIZE}";
                        check = false
                    } else {
                        check = true
                    }
                }
            });

        if (sizeError) {
            store.dispatch(
                showBottomNotification(t(sizeError).replace('{FILE_SIZE}', `${maxFileSize}MB`), {
                    isFail: true,
                }));
            check = false
        }

        if (articleDescription == null || articleDescription == "") {
            store.dispatch(
                showBottomNotification(t('Article.Description should not be empty'), {
                    isFail: true,
                }))
            check = false;
        }
        return check;
    }

    const goToEditPage = () => {
        setIsShowMode(false);
        setIsEditMode(true);
        if (noArticle) {
            setEditor(true)
        }
    }

    const goToBackPage = () => {
        window.history.go(-1);
    }

    const deleteFullArticle = () => {
        setIsLoading(true);
        deleteArticle(articleId).then((res) => {
            store.dispatch(
                showBottomNotification(t('Article.Successfully Deleted'), {
                    isFail: false,
                }))
                setIsLoading(false);

            goToBackPage();
        }).catch((res) => {setIsLoading(false)})
    }

    const { Editor, getEditorValue, updateValue } = useEditor({
        placeholder: t('Chat.Message Placeholder'),
        onChange: () => {
            const value = getEditorValue();
            setArticleDescription(checkLink(value));
        },
        className: styles.textareaContent,
    });

    useEffect(() => {
        // Cleanup function to remove event listener when component unmounts
        return () => {
            if (fileInputRef.current) {
                fileInputRef.current.removeEventListener("change", handleChange);
            }
        };
    }, [fileInputRef.current, handleChange]); // Dependency array

    const [editor, setEditor] = useState(false);

    const openEditor = () => {
        setEditor(true);
        const descWrapped = document.createElement('div');
        descWrapped.innerHTML = articleDescription;
        updateValue(descWrapped);
    }

    return (
        <KitaPanelWrapper>

            <Wrapper>
                <div className={styles.fileupload_view}>
                    <div className="col-md-6">
                        <div className={styles.card}>
                            <div className={styles.card_body}>

                                <div className={styles.first_article_heading}>
                                    Unser "Zuhause“, unsere Begegnungsstätte
                                </div>
                                {isEditMode && (<div>
                                    <span className={styles.first_article_title}>Beschreibt hier eure Begegnungsstätte (z.B.
                                        technische Ausstattung, vorhandene Räume und
                                        Buchungsmöglichkeiten, etc.)</span>
                                    <div className={editor ? styles.editorShow : styles.editorNoShow}><Editor /></div>
                                    {!editor && <span onClick={() => openEditor()}><div
                                        className={styles.eventInfoDescription}
                                        dangerouslySetInnerHTML={{ __html: sanitize(articleDescription) }}
                                    /></span>}
                                    <div className={styles.kb_data_box}>

                                        <form onSubmit={handleFileUploadSubmit}>
                                            <div className={styles.kb_file_upload}>
                                                <div className={styles.file_upload_box}>
                                                    <input
                                                        type="file"
                                                        id="fileupload"
                                                        className={styles.file_upload_input}
                                                        ref={fileInputRef} // Reference for event listener
                                                        onChange={handleChange}
                                                        multiple
                                                    />
                                                    <span>
                                                        Drag & Drop oder <span className={styles.file_link}>Wählen Sie Ihre Datei</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={styles.kb_attach_box}>
                                                {
                                                    selectedFiles.map((data, index) => {
                                                        const { id, fileId, filePath, fileDescription } = data;
                                                        return (
                                                            <div className={styles.file_atc_box} key={id}>
                                                                {
                                                                    fileId?.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                        <div className={styles.file_image}> <img src={filePath} alt="" /></div> :
                                                                        <div className={styles.file_image}><i className="far fa-file-alt"></i></div>
                                                                }
                                                                <div className={styles.file_detail}>
                                                                    <h6>{fileId}</h6>
                                                                    <p></p>
                                                                    <p></p>
                                                                    <div className={styles.file_actions}>
                                                                        <button type="button" className={styles.file_action_btn} onClick={() => handleDeleteSelectFile(id, index)}>Löschen</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className={styles.kb_buttons_box}>
                                                <Button type="submit" className={styles.submitButton} onClick={() => submitArticle()}>Hochladen</Button>


                                                <Button onClick={() => goToBackPage()} className={styles.backButton} type='primary'>
                                                    {t('Zurück zur Hauptseite')}
                                                </Button>
                                            </div>
                                        </form>
                                    </div>
                                </div>)}

                                {isShowMode && (
                                    <><>
                                        {!noArticle ? (

                                            <><div
                                                className={styles.eventInfoDescription}
                                                dangerouslySetInnerHTML={{ __html: sanitize(articleDescription) }}
                                            /><div className={styles.box}>
                                                    <Carousel useKeyboardArrows={true}>
                                                        {carouselImages?.map((URL, index) => (
                                                            <div className="slide">
                                                                <img alt="sample_file" src={URL} key={index} />
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                </div></>) : (
                                            <div className={styles.noArticle}>
                                                <p>{t('Article.No Articles to be shown')}</p>
                                            </div>
                                        )}</>
                                        {(user.administrationAccess || user.superAdminStatus) && (
                                            <Button onClick={() => goToEditPage()} className={styles.editButton} type='primary'>
                                                {!noArticle ? t('Artikel bearbeiten') : t('Neuen Artikel erstellen')}
                                            </Button>
                                        )}
                                        {!noArticle && (<Button onClick={() => deleteFullArticle()} className={styles.backButton} type='primary'>
                                            {t('Artikel löschen')}
                                        </Button>)}
                                        <Button onClick={() => goToBackPage()} className={styles.backButton} type='primary'>
                                            {t('Zurück zur Hauptseite')}
                                        </Button></>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

                {deletePopup && (<Popup
                    closePopup={() => setDeletePopup(false)}
                    isOpened
                    footer={(
                        <div className={styles.buttonsContainer}>
                            <div className={styles.buttonSubmit}>
                                <Button onClick={() => deleteItem(deleteID, deleteIndex)} type='primary'>
                                    {t('AccountSettings.AbsenceSettings.Yes')}
                                </Button>
                            </div>
                            <div className={styles.buttonSubmit}>
                                <Button onClick={() => setDeletePopup(false)} type='primary'>
                                    {t('AccountSettings.AbsenceSettings.No')}
                                </Button>
                            </div>
                        </div>
                    )
                    }
                >
                    <div className={styles.containerEnable}>
                        <h2>Sind Sie sicher, dass Sie die Bilder löschen möchten?</h2>
                    </div>
                </Popup>)}
            </Wrapper>
        </KitaPanelWrapper>

    );
};

export default connect(state => ({
    activeKita: state.kitaReducer.activeKita,
    user: state.userReducer,
})
)(FourthArticle);
