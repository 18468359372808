import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'library/common/commonComponents/Card';
import Tabs from 'library/common/commonComponents/Tabs';

import MembersFrame from './GroupBodyMembersFrames/Members';
import MembershipRequestFrame from './GroupBodyMembersFrames/MembershipRequestFrame';

import styles from './groupBodyMembers.module.scss';

export default function GroupBodyMembers() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: t('GroupBody.Members.Members'),
      path: 'member-list',
      component: <MembersFrame />,
    },
    {
      title: t('GroupBody.Members.Membership Requests'),
      path: 'membership-requests',
      component: <MembershipRequestFrame />,
    },
  ];
  return (
    <Card title={<Trans i18nKey='GroupBody.Members.Title' />} className={styles.body}>
      <Tabs
        tabs={tabs}
        onChange={(_, index) => setActiveTab(index)}
        currentTab={tabs[activeTab].path}
      />
    </Card>
  );
}
