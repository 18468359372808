import React, { useState } from 'react';
import SkyWrapper from './SkyWrapper';
import NameInput from './NameInput';
import Overview from './Overview';
import { useHistory } from 'react-router-dom';
import Button from 'library/common/commonComponents/Buttons/Button';
import styles from './awoww.module.scss';
import AWODashboardOverview from 'modules/AWODashboardOverview';
import Popup from 'library/common/commonComponents/Popups/Popup';

export default function Awoww() {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [popupOpened, setPopupOpened] = useState(false);

  const titleArray = [
    <>Gib deinen Standort an</>,
    <>Gib deinen<br />
      Namen an</>,
    <>Übersicht</>
  ];

  const bodyArray = [
    <></>,
    <>
      <NameInput
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
      />
    </>,
    <>
      <Overview firstName={firstName} lastName={lastName} selectedPlaces={selectedPlaces} />
    </>,
    <></>,
    <></>
  ];

  const closeAndNavigate = () => {
    const selectedKitaIds = [];
    const selectedGroupIds = [];

    Object.keys(selectedItems).forEach(city => {
      const cityData = selectedItems[city];
      Object.entries(cityData.kitas).forEach(([kitaId, kita]) => {
        if (kita.isChecked) {
          selectedKitaIds.push(kitaId);
        } else {
          Object.entries(kita.groups).forEach(([groupId, isChecked]) => {
            if (isChecked) {
              selectedGroupIds.push(groupId);
            }
          });
        }
      });
    });

    localStorage.setItem('selectedKitas', JSON.stringify(selectedKitaIds));
    localStorage.setItem('selectedGroups', JSON.stringify(selectedGroupIds));

    setPopupOpened(false);

    if (selectedKitaIds.length > 0 || selectedGroupIds.length > 0) {
      history.push({
        pathname: "/login",
        state: {
          autologin: true
        }
      });
    }
  }

  const footerArray = [
    <>
      <div className={styles.buttonContainer}>
        <Button
          type='awoww'
          onClick={() => {
            setPopupOpened(true);
          }}>
          <b>Ortsauswahl</b>
        </Button>
        <Button
          type='awoww'
          onClick={() => {
            history.push({
              pathname: "/login",
            });
          }}>
          <b>Weiter zum Login</b>
        </Button>
      </div>
    </>,
    <>
      <Button
        className={styles.awowwdoublebottombuttonLeft}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}>
        <b>Zurück</b>
      </Button>
      <Button
        className={styles.awowwdoublebottombuttonRight}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage + 1);
        }}>
        <b>Zur Übersicht</b>
      </Button>
    </>,
    <>
      <Button
        className={styles.awowwdoublebottombuttonLeft}
        type='awoww'
        onClick={() => {
          setCurrentPage(currentPage - 1);
        }}>
        <b>Zurück</b>
      </Button>
      <Button
        className={styles.awowwdoublebottombuttonRight}
        type='awoww'
        onClick={() => {
          history.push(`/login`);
        }}>
        <b>Fertig</b>
      </Button>
    </>
  ];

  return (
    <div>
      <SkyWrapper
        title={titleArray[currentPage]}
        body={bodyArray[currentPage]}
        footer={footerArray[currentPage]}
      />
      <Popup
        isOpened={popupOpened}
        size='awoPopup'
        closePopup={() => setPopupOpened(false)}
        header='Bitte Ortsauswahl treffen'
        body={
          <div className={styles.awoWrapper}>
            <AWODashboardOverview publicUser={true} setSelectedItems={setSelectedItems} />
          </div>
        }
        footer={
          <div>
            <Button type='awoww' onClick={() => closeAndNavigate()}>Schließen</Button>
          </div>
        }
      />
    </div>
  );
}
