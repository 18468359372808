import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { getDPP, createDPP, getAllKitasWithAllData } from 'library/api/kita';
import { sanitize } from 'dompurify';

import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Button from 'library/common/commonComponents/Buttons/Button';
import Checkbox from 'library/common/commonComponents/Checkbox';
import Card from 'library/common/commonComponents/Card';
import Loader from 'library/common/commonComponents/Loader';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import CreateKita from 'library/common/commonComponents/CreateKita/CreateKitaContainer';
import store from 'main/store/configureStore';

import DataProtectionFeed from './DataProtectionFeed';
import AppLogo from '../../library/common/commonComponents/AppLogo';

import styles from './DataProtectionModule.module.scss';
import ProfileGdpr from 'modules/AccountSettings/accountSettingsFrames/ProfileFrame/profileFrameFrames/ProfileGdpr';
import { isAwoWW } from 'library/api/tenantConfig';

export default function DataProtectionModule({ isLoggedIn, isAdmin, activeKita, user }) {
  const [isEditPopupOpened, setIsEditPopupOpened] = useState(false);
  const { history } = useReactRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [dPP, setDPP] = useState([]);
  const [option, setOption] = useState('stand');
  if (
    (window.location.hostname.toLowerCase().includes('awoconnect.safe2connect.org') ||
      window.location.hostname.toLowerCase().includes('awobamberg.safe2connect.org')) &&
    user.superAdminStatus === false
  ) {
    isAdmin = false;
  }
  const [kitas, setKitas] = useState([]);

  const [currentKita, setCurrentKita] = useState();

  const linkItIsiDefaultDataProtectionOfficer = {
    dPOCompany: null,
    dPOName: 'Thomas Bischoff (ext. Datenschutzbeauftragter)',
    dPOStreet: 'Schinkelstr. 33',
    dPOZip: '40211',
    dPOCity: 'Düsseldorf',
    dPOPhone: null,
    dPOMail: 'datenschutz@safe2connect.de',
  };

  const linkItIsiDefaultDataProtectionResponsibleBody = {
    dPCompany: 'Link IT isi GmbH',
    dPName: 'Sebastian Kopp',
    dPStreet: 'Kleestraße 21-23',
    dPZip: '90461',
    dPCity: 'Nürnberg',
    dPPhone: '+49 911 14609820',
    dPMail: 'info@link-it-isi.de',
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      getAllKitasWithAllData().then(res => {
        setKitas(res.data.content);
      });

      getDPP().then(res => {
        setDPP(res.data);
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    kitas.map(kita => {
      if (activeKita.kitaId === kita.id) {
        setCurrentKita(kita);
      }
    });
  });

  useEffect(() => {
    if (dPP.setting === 'own') {
      setOption('own');
    } else if (dPP.setting === 'both') {
      setOption('both');
    } else {
      setOption('stand');
    }
  }, [dPP]);

  if (activeKita.virtual === true) {
    return (
      <KitaPanelWrapper>
        <Wrapper>
          <Col>
            <Row>
              <div
                style={{
                  background: '#fff',
                  padding: '30px',
                  boxShadow: !isLoggedIn && '0 0 15px #627d92',
                  margin: !isLoggedIn && '50px 0',
                }}
              >
                {t('DataProtection.Please select a institution to see the data protection policy')}
              </div>
            </Row>
          </Col>
        </Wrapper>
      </KitaPanelWrapper>
    );
  }

  let dPOName = 'Name';
  let dPOCompany = 'Firmenname';
  let dPOStreet = 'Straße';
  let dPOZip = 'Postleitzahl';
  let dPOCity = 'Stadt';
  let dPOPhone = 'Telefonnummer';
  let dPOMail = 'eMail Adresse';

  if (Object.keys(activeKita).length > 0 && isLoggedIn) {
    if (activeKita.dataProtectionOfficerName !== null) {
      dPOCompany = activeKita.dataProtectionOfficerCompany;
      dPOName = activeKita.dataProtectionOfficerName;
      dPOStreet = activeKita.dataProtectionOfficerStreet;
      dPOZip = activeKita.dataProtectionOfficerZip;
      dPOCity = activeKita.dataProtectionOfficerCity;
      dPOPhone = activeKita.dataProtectionOfficerPhone;
      dPOMail = activeKita.dataProtectionOfficerEmail;
    }
  } else {
    dPOCompany = linkItIsiDefaultDataProtectionOfficer.dPOCompany;
    dPOName = linkItIsiDefaultDataProtectionOfficer.dPOName;
    dPOStreet = linkItIsiDefaultDataProtectionOfficer.dPOStreet;
    dPOZip = linkItIsiDefaultDataProtectionOfficer.dPOZip;
    dPOCity = linkItIsiDefaultDataProtectionOfficer.dPOCity;
    dPOPhone = linkItIsiDefaultDataProtectionOfficer.dPOPhone;
    dPOMail = linkItIsiDefaultDataProtectionOfficer.dPOMail;
  }

  let dPName = 'Name';
  let dPCompany = 'Firmenname';
  let dPStreet = 'Straße';
  let dPZip = 'Postleitzahl';
  let dPCity = 'Stadt';
  let dPPhone = 'Telefonnummer';
  let dPMail = 'eMail Adresse';

  if (Object.keys(activeKita).length > 0 && isLoggedIn) {
    if (activeKita.dataProcessingName !== null) {
      dPCompany = activeKita.dataProcessingCompany;
      dPName = activeKita.dataProcessingName;
      dPStreet = activeKita.dataProcessingStreet;
      dPZip = activeKita.dataProcessingZip;
      dPCity = activeKita.dataProcessingCity;
      dPPhone = activeKita.dataProcessingPhone;
      dPMail = activeKita.dataProcessingEmail;
    }
  } else {
    dPCompany = linkItIsiDefaultDataProtectionResponsibleBody.dPCompany;
    dPName = linkItIsiDefaultDataProtectionResponsibleBody.dPName;
    dPStreet = linkItIsiDefaultDataProtectionResponsibleBody.dPStreet;
    dPZip = linkItIsiDefaultDataProtectionResponsibleBody.dPZip;
    dPCity = linkItIsiDefaultDataProtectionResponsibleBody.dPCity;
    dPPhone = linkItIsiDefaultDataProtectionResponsibleBody.dPPhone;
    dPMail = linkItIsiDefaultDataProtectionResponsibleBody.dPMail;
  }

  const saveOption = () => {
    if (option === 'stand') {
      createDPP({ setting: 'standard' });
    }
    if (option === 'own') {
      createDPP({ setting: 'own' });
    }
    if (option === 'both') {
      createDPP({ setting: 'both' });
    }
    store.dispatch(
      showBottomNotification(t('BottomNotifications.Saved'), {
        isFail: false,
      }),
    );
  };

  const stramplerbandeDPP = (
    <React.Fragment>
      <strong>
        {' '}
        {<Trans i18nKey='DataProtection.General notice and mandatory information' />}{' '}
      </strong>
      <br />
      <br />
      <div className='markdown-render'>
        <React.Fragment>
          <Card>
            <p>
              <strong>
                {t('DataProtection.The responsible body for data processing on this website is')}:
              </strong>
              <br />
              {isLoggedIn ? (
                <div>
                  {dPCompany !== null && <div>{dPCompany}</div>}
                  {dPName !== null && <div>{dPName}</div>}
                  {dPStreet !== null && <div>{dPStreet}</div>}
                  {(dPZip !== null || dPCity !== null) && (
                    <div>
                      {dPZip} {dPCity}
                    </div>
                  )}
                  {dPPhone !== null && <div>{dPPhone}</div>}
                  {dPMail !== null && <div>{dPMail}</div>}
                </div>
              )
                : (
                  <div>
                    <div>{t('DataProtection.Responsible company')}</div>
                    <div>{t('DataProtection.Responsible full name')}</div>
                    <div>{t('DataProtection.Responsible street')}</div>
                    <div>
                      {t('DataProtection.Responsible zip and city')}
                    </div>
                    <div>{t('DataProtection.Responsible phone')}</div>
                    <div>{t('DataProtection.Responsible mail')}</div>
                  </div>
                )}
            </p>
            <br />
            {Object.keys(activeKita).length > 0 && isLoggedIn && (
              <span>
                <p>
                  <strong>{t('DataProtection.Institution address')}:</strong>
                  <br />
                  {activeKita.kitaName}
                  <br />
                  {activeKita.streetName} {activeKita.houseNo}
                  <br />
                  {activeKita.zipCode} {activeKita.city}
                  <br />
                </p>
                <br />
              </span>
            )}
            <p>
              <strong>
                {t("The institution's data protection officer is")}
                {activeKita && activeKita.kitaName && isLoggedIn
                  ? ` (${activeKita.kitaName}):`
                  : `:`}
              </strong>
              <br />
              {isLoggedIn ? (
                <div>
                  {dPOCompany !== null && <div>{dPOCompany}</div>}
                  {dPOName !== null && <div>{dPOName}</div>}
                  {dPOStreet !== null && <div>{dPOStreet}</div>}
                  {(dPOZip !== null || dPOCity !== null) && (
                    <div>
                      {dPOZip} {dPOCity}
                    </div>
                  )}
                  {dPOPhone !== null && <div>{dPOPhone}</div>}
                  {dPOMail !== null && <div>{dPOMail}</div>}
                </div>
              ) : (
                <div>
                  <div>{t('DataProtection.Dpo company')}</div>
                  <div>{t('DataProtection.Dpo full name')}</div>
                  <div>{t('DataProtection.Dpo street')}</div>
                  <div>
                    {t('DataProtection.Dpo zip and city')}
                  </div>

                  <div>{t('DataProtection.Dpo phone')} </div>
                  <div>{t('DataProtection.Dpo mail')} </div>
                </div>
              )}
            </p>
            {isAdmin && (
              <React.Fragment>
                <br />
                <Button onClick={() => setIsEditPopupOpened(true)}>
                  <i className='fa fa-pencil' style={{ marginRight: 5 }} />
                </Button>
                <br />
              </React.Fragment>
            )}
          </Card>
        </React.Fragment>
        {<Trans i18nKey='DataProtection.Responsible body and revocation' />}
        <br />
        <br />
        {<Trans i18nKey='DataProtection.Right to complain' />}
        <br />
        <br />
        {
          <Trans
            i18nKey='DataProtection.Right to data transfer and information
                      correction, blocking, deletion'
          />
        }
        <br />
        <strong> {<Trans i18nKey='DataProtection.Subcompanie of Stramplerbande' />} </strong>
        <table style={{ borderSpacing: '5px 0' }}>
          <tbody>
            <tr>{<Trans i18nKey='DataProtection.Subcompanies one' />}</tr>
            {<Trans i18nKey='DataProtection.Subcompanies two' />}
          </tbody>
        </table>
      </div>
      {isAwoWW() && (
        <ProfileGdpr />)}
      {' '}
    </React.Fragment>
  );

  const awobambergGDPR = (
    <Wrapper>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <AppLogo />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button onClick={() => history.goBack()}>
          <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
        </Button>
      </div>
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            <b>Information zum sozialen Netzwerk „AWO online – Bamberg“</b>
            <br />
            <br />
            <p>
              Die AWO Bamberg stellt den Eltern in Zusammenarbeit mit der Link IT isi GmbH,
              Kleestraße 21-23, 90461 Nürnberg ein soziales Netzwerk zur Information der Eltern
              sowie zur Kommunikation mit Eltern der Einrichtung zur Verfügung. Wir haben diese APP
              ausgewählt, da für die Link IT isi GmbH der Schutz Ihrer personenbezogenen Daten
              höchste Priorität genießt. Ihre Daten werden nur entsprechend der für den Zweck
              unseres sozialen Netzwerks erforderlichen Maße verarbeitet. Dabei halten wir uns stets
              an gesetzliche Grundlagen – insbesondere an die Anforderungen der
              Datenschutzgrundverordnung.
            </p>
            <br />
            <p>
              In dieser Einwilligungserklärung informieren wir Sie über die Verarbeitung Ihrer
              personenbezogenen Daten und über Ihre Rechte im Rahmen des Online-Angebots. Das
              Netzwerk wird in zertifizierten deutschen Rechenzentren betrieben, welche redundant
              ausgelegt sind und somit für eine hohe Stabilität und Sicherheit sorgen.
            </p>
            <br />
            <p>
              Um Ihnen diesen Dienst anbieten zu können, benötigen wir allerdings Ihre
              Einwilligungserklärung sowie Ihre personenbezogenen Daten. Wir bitten Sie, die Angaben
              auf der Folgeseite zu vervollständigen und die Seite unterschrieben an uns
              zurückzusenden.
            </p>
            <br />
            <b>Informationen zum Datenschutz nach Artikel 13 der EU-Datenschutz-Grundverordnung</b>
            <br />
            <br />
            <p>
              Im Rahmen der Eltern-/Einrichtungskommunikation über ein soziales Netzwerk werden
              personenbezogene Daten von Ihnen durch den Arbeiterwohlfahrt Kreisverband Bamberg
              Stadt und Land e.V., Hauptsmoorstraße 26a, 96052 Bamberg,{' '}
              <a href='www.awo-bamberg.de'>www.awo-bamberg.de</a>, verarbeitet.
            </p>
            <br />
            <p>
              Bei diesen personenbezogenen Daten handelt es sich um erforderliche Angaben zur oben
              genannten Nutzung. Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Absatz 1
              Satz 1 a [Einwilligung] der betroffenen Person und werden für oben genannte Zwecke
              verwendet. Konto- und Kommunikationsdaten werden spätestens nach 2 Jahren gelöscht,
              sobald die Einrichtung dauerhaft verlassen wird. Sie haben das Recht, Auskunft darüber
              zu verlangen, welche Daten über Sie gespeichert sind und zu welchem Zweck diese
              Speicherung erfolgt. Darüber hinaus können Sie unrichtige Daten be-richtigen oder
              solche Daten löschen lassen, deren Speicherung nicht mehr erforderlich ist. Sie haben
              das Recht auf Datenübertragbarkeit. Beim Auskunfts- und Löschungsrecht gelten die
              Einschränkungen nach §34 und §35 BDSG. Außerdem haben Sie das Recht, sich bei einer
              Aufsichtsbehörde über die stattfindende Datenverarbeitung zu beschweren. Für uns ist
              das Bayerische Landesamt für Datenschutzaufsicht (BayLDA{' '}
              <a href='www.lda.bayern.de'>www.lda.bayern.de</a>) zuständig. Bei Unklarheiten können
              Sie sich jederzeit an unseren Datenschutzbeauftragten unter{' '}
              <b>Datenschutz@AWO-Bamberg.de</b> wenden. Leistungstitel{' '}
              <b>Einwilligungserklärung zur Nutzung des soziales Netzwerks „AWO online“</b>{' '}
              Geltungsbereich <b>FB KiJuFa Organisation</b> AWO KV
            </p>
            <br />
            <p>
              <b>Ergänzende Nutzungshinweise</b>
            </p>
            <br />
            <p>
              <b>
                Allgemeine Pflichten des Nutzers und besondere Bestimmungen für die Nutzung des
                sozialen Netzwerks
              </b>
            </p>
            <br />
            <p>
              Der Nutzer ist verpflichtet, bei der Nutzung des sozialen Netzwerks sowie der Inhalte
              geltendes Recht sowie alle Rechte Dritter zu beachten. Es ist dem Nutzer insbesondere
              untersagt:
            </p>
            <br />
            <ul>
              <li>
                Bild- und Videodateien, welche im sozialen Netzwerks veröffentlicht werden, dürfen
                nur zum eigenen Gebrauch gespeichert und nicht weiterverbreitet werden
              </li>
              <li>
                Verwendung beleidigender oder verleumderischer Inhalte, unabhängig davon, ob diese
                In-halte andere Nutzer, Mitarbeiter oder andere Personen oder Unternehmen betreffen.
              </li>
              <li>
                Verwendung pornografischer, gewaltverherrlichender, missbräuchlicher, sittenwidriger
                oder Jugendschutzgesetze verletzende Inhalte oder Bewerbung, Angebot und/oder
                Vertrieb von pornografischen, gewaltverherrlichenden, missbräuchlichen,
                sittenwidrigen oder Jugendschutzgesetze verletzende Waren oder Dienstleistungen.
              </li>
              <li>
                Verwendung von gesetzlich (z. B. durch das Urheber-, Marken-, Patent-,
                Geschmacksmuster- oder Gebrauchsmusterrecht) geschützten Inhalten, ohne dazu
                berechtigt zu sein.
              </li>
              <li>
                Änderungen bezüglich erziehungsberechtigter Personen sind unmittelbar an die Leitung
                der Kindertageseinrichtung zu kommunizieren, insbesondere, wenn damit die Sperre
                eines Accounts einhergeht.
              </li>
              <li>
                Der Account (Benutzername, E-Mail-Adresse, KiTa-Code) für den Zugang zum sozialen
                Netz-werk darf unter keinen Umständen an Dritte Personen weitergegeben werden.
              </li>
            </ul>
            <br />
            <p>
              <b>Folgen bei missbräuchlichem Gebrauch des sozialen Netzwerks</b>
            </p>
            <br />
            <p>
              Der Account von Nutzern kann unmittelbar und ohne Angabe von Gründen durch den
              Gruppenadministrator der AWO Bamberg gesperrt werden. Dies gilt insbesondere bei
              missbräuchlichem Gebrauch der APP.
            </p>
          </div>
        </Row>
      </Col>
    </Wrapper>
  );

  const indagGDPR = (
    <Wrapper>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <AppLogo />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button onClick={() => history.goBack()}>
          <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
        </Button>
      </div>
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            <h2 style={{ color: '#777', fontSize: '1.5em' }}>
              Datenschutzerklärung Intranet und Austauschplattform der Alzheimer-Gesellschaften in
              Deutschland
            </h2>
            <h1 style={{ color: 'red', fontSize: '2em' }}>
              Datenschutzhinweise
              <br />
              „Intranet der Deutschen Alzheimer Gesellschaft e.V. Selbsthilfe Demenz“
            </h1>
            <p>Stand: 31. Mai 2021</p>
            <br />
            <br />
            <p>
              Für die Zusammenarbeit der Mitgliedsorganisationen und Gremien in der „Deutschen
              Alzheimer Gesellschaft“ ist ein webbasiertes Intranet eingerichtet. Im folgenden
              informieren wir Sie darüber wie mit Ihren personenbezogenen Daten umgegangen wird:
            </p>
            <br />
            <br />
            <b style={{ fontSize: '1.5em' }}>Kurzfassung</b>
            <br />
            <p>
              Sie als Nutzer*innen sind aktiv einer unserer Mitgliedsorganisationen. Für die
              Bereitstellung des Intranets haben wir uns für die Lösung „safe2connect“ entschieden
              und mit dem Anbieter einen Vertrag zur Verarbeitung von personenbezogenen Daten im
              Auftrag (gem. Art 28 DSGVO) abgeschlossen. Sie nutzen das Intranet über das Internet
              mittels Webbrowser oder einer App. Dabei werden Daten wie Ihre IP-Adresse oder auch
              Informationen zu aufgetretenen Fehler verarbeitet. Ferner nutzen Sie das Intranet bzw.
              die Austauschplattform INDAG und geben Informationen ein. Sie steuern dabei, welche
              Inhalte Sie angeben. Alle Daten werden ausschließlich für den sicheren Betrieb des
              Intranets verarbeitet.
            </p>
            <br />
            <br />
            <b>1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wer ist für Datenverarbeitung verantwortlich?</b>
            <p>Verantwortlicher im Sinne des Datenschutzrechts ist die</p>
            <p>Deutsche Alzheimer Gesellschaft e.V.</p>
            <p>Selbsthilfe Demenz</p>
            <p>Friedrichstr. 236</p>
            <p>10969 Berlin</p>
            <p>
              E-Mail: <a href='mailto: info@deutsche-alzheimer.de'>info[at]deutsche-alzheimer.de</a>
            </p>
            <p>
              Sie finden weitere Informationen zu unserem Verein, Angaben zu den
              vertretungsberechtigten Personen und auch weitere Kontaktmöglichkeiten in unserem
              Impressum .
            </p>
            <br />
            <br />
            <br />
            <b>
              2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welche Daten von Ihnen werden von uns verarbeitet? Und
              zu welchen Zwecken?
            </b>
            <p>
              Bei der Nutzung von INDAG werden verschiedenste Daten von Ihnen zum Zweck des
              Betriebes und dem Zweck der guten Zusammenarbeit verarbeitet. Dabei steuern Sie
              weitestgehend welche Daten Sie eingeben wollen. In INDAG werden regelmäßig die
              folgende Daten verarbeitet:
            </p>
            <p>
              Stamm- und Kontaktdaten (u.a. Name, Emailadresse, Straße, Postleitzahl, Telefonnummer)
            </p>
            <p>
              Profilinformationen (User Name, Verantwortungsebene, Beruf, Kontobezeichnung sozialer
              Medien, Berufsbezeichnung, Geschlecht, Nationalität, Alter)
            </p>
            <p>Kommunikationsinhalte</p>
            <p>
              Mobile-Geräte-Daten (Geräte- und Kartenkennungen, Standortdaten, Sicherheitsmerkmale,
              Mobilfunknummer, etc.) Die genauen Daten erfahren Sie aus den Datenschutzhinweise der
              App safe2connet, sowie die Datenschutzhinweise des jeweiligen App-Shop.
            </p>
            <p>
              Eine Datenverarbeitung zu anderen Zwecken kommt nur dann in Betracht, wenn die
              insoweit erforderlichen rechtlichen Vorgaben gemäß Art. 6 Abs. 4 DSGVO vorliegen.
              Etwaige Informationspflichten nach Art. 13 Abs. 3 DSGVO und Art. 14 Abs. 4 DSGVO
              werden wir in dem Fall selbstverständlich beachten.
            </p>
            <br />
            <br />
            <br />
            <b>3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Auf welcher rechtlichen Grundlage basiert das?</b>
            <p>
              Wir verarbeiten Ihre personenbezogenen Daten auf Grund eines zumindest
              vertragsähnlichen Verhältnisses zur Zusammenarbeit mit INDAG (Art. 6 Abs. 1. lit. b)
              DSGVO).
            </p>
            <p>
              Hilfsweise können wir Sie auch um Ihre Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO)
              bitten oder Daten von Ihnen auf Basis einer Interessenabwägung (Art. 6 Abs. 1 lit. f)
              DSGVO) verarbeiten.
            </p>
            <p>
              Wenn personenbezogene Daten auf Grundlage einer <b>Einwilligung</b> von Ihnen
              verarbeitet werden, haben Sie das Recht, die Einwilligung jederzeit mit Wirkung für
              die Zukunft uns gegenüber zu <b>widerrufen</b>. Schicken Sie dafür einfach eine E-Mail
              mit einem entsprechenden Hinweis an{' '}
              <a href='mailto: info@deutsche-alzheimer.de'>info@deutsche-alzheimer.de</a>.
            </p>
            <p>
              Wenn wir Daten auf Basis einer <b>Interessenabwägung</b> verarbeiten, haben Sie als
              Betroffene*r das Recht, unter Berücksichtigung der Vorgaben von Art. 21 DSGVO der
              Verarbeitung der personenbezogenen Daten zu <b>widersprechen</b> .
            </p>
            <br />
            <br />
            <br />
            <b>4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wie lange werden die Daten gespeichert?</b>
            <p>
              Wir verarbeiten die Daten, solange dies für den jeweiligen Zweck erforderlich ist. In
              der Regel bleiben die als nicht dauerhaft markierten Inhalte in INDAG für zwei Jahre
              bestehen.
            </p>
            <p>
              Grundsätzlich nehmen wir gegen Ende eines Kalenderjahres eine Prüfung von Daten im
              Hinblick auf das Erfordernis einer weiteren Verarbeitung vor. Aufgrund der Menge der
              Daten erfolgt diese Prüfung im Hinblick auf spezifische Datenarten oder Zwecke einer
              Verarbeitung.
            </p>
            <p>
              Selbstverständlich können Sie jederzeit (s.u.) Auskunft über die bei uns zu Ihrer
              Person gespeicherten Daten verlangen und im Falle einer nicht bestehenden
              Erforderlichkeit eine Löschung der Daten oder Einschränkung der Verarbeitung
              verlangen.
            </p>
            <br />
            <br />
            <br />
            <b>
              5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An welche Empfänger werden die Daten weitergegeben?
            </b>
            <p>
              Eine Weitergabe Ihrer personenbezogenen Daten an Dritte findet grundsätzlich nur
              statt, wenn dies für die Durchführung des Vertrages mit Ihnen erforderlich ist, die
              Weitergabe auf Basis einer Interessenabwägung i.S.d. Art. 6 Abs. 1 lit. f) DSGVO
              zulässig ist, wir rechtlich zu der Weitergabe verpflichtet sind oder Sie insoweit eine
              Einwilligung erteilt haben.
            </p>
            <p>
              Für den Betrieb von INDAG haben wir uns für das Produkt safe2connect entschieden und
              mit dem Betreiber (Link IT isi GmbH) einer Vereinbarung zur Verarbeitung von
              personenbezogenen Daten im Auftrag gem. Art. 28 DSGVO geschlossen.
            </p>
            <br />
            <br />
            <br />
            <b>6&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Wo werden die Daten verarbeitet?</b>
            <p>
              Ihre personenbezogenen Daten werden von uns ausschließlich in der Bundesrepublik
              Deutschland oder der Europäischen Union verarbeitet. Bei der Nutzung von INDAG über
              das Internet kann jedoch nicht ausgeschlossen werden, dass Daten von Ihnen in ein
              Drittland übertragen werden.
            </p>
            <br />
            <br />
            <br />
            <b>7&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ihre Rechte als „Betroffene“</b>
            <p>
              Sie haben das Recht auf Auskunft über die von uns zu Ihrer Person verarbeiteten
              personenbezogenen Daten.
            </p>
            <p>
              Bei einer Auskunftsanfrage, die nicht schriftlich erfolgt, bitten wir um Verständnis
              dafür, dass wir dann ggf. Nachweise von Ihnen verlangen, die belegen, dass Sie die
              Person sind, für die Sie sich ausgeben.
            </p>
            <p>
              Ferner haben Sie ein Recht auf Berichtigung oder Löschung oder auf Einschränkung der
              Verarbeitung, soweit Ihnen dies gesetzlich zusteht.
            </p>
            <p>
              Ferner haben Sie ein Widerspruchsrecht gegen die Verarbeitung im Rahmen der
              gesetzlichen Vorgaben. Gleiches gilt für ein Recht auf Datenübertragbarkeit.
            </p>
            <p>
              <b>
                Insbesondere haben Sie ein Widerspruchsrecht nach Art. 21 Abs. 1 und 2 DSGVO gegen
                die Verarbeitung Ihrer Daten im Zusammenhang mit einer Direktwerbung, wenn diese auf
                Basis einer Interessenabwägung erfolgt.
              </b>
            </p>
            <br />
            <br />
            <br />
            <b>8&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unser Datenschutzbeauftragter</b>
            <p>
              Wir haben einen Datenschutzbeauftragten in unserem Unternehmen benannt. Sie erreichen
              ihn unter folgenden Kontaktmöglichkeiten:
            </p>
            <p>Selbsthilfe Demenz</p>
            <p>- Datenschutzbeauftragter -</p>
            <p>Friedrichstr. 236</p>
            <p>10969 Berlin</p>
            <p>
              E-Mail: <a href='mailto:datenschutz@dalzg.de'>datenschutz@dalzg.de</a>
            </p>
            <br />
            <br />
            <br />
            <b>9&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Beschwerderecht</b>
            <p>
              Sie haben das Recht, sich über die Verarbeitung personenbezogener Daten durch uns bei
              einer Aufsichtsbehörde für den Datenschutz zu beschweren.
            </p>
            <br />
          </div>
        </Row>
      </Col>
    </Wrapper>
  );

  const awoconnectGDPR = (
    <Wrapper>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <AppLogo />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button onClick={() => history.goBack()}>
          <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
        </Button>
      </div>
      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            <b style={{ fontSize: '24pt' }}>Datenschutzinformation</b>
            <br />
            <br />
            <p>
              Der AWO Unterbezirk Münsterland-Recklinghausen stellt seinen Beschäftigten und
              Kund*innen in Zusammenarbeit mit der{' '}
              <a href='https://www.link-it-isi.de/'>Link IT isi GmbH</a> aus Nürnberg ein soziales
              Netzwerk zur Information und Kommunikation zur Verfügung. Für den AWO Unterbezirk
              genießt der Schutz personenbezogener Daten höchste Priorität. Wir haben den
              Dienstleister Link IT isi GmbH beauftragt, das soziale Netzwerk AWO Connect für uns zu
              betreiben. Ihre Daten werden von Link IT isi GmbH nur in unserem Auftrag und nur für
              den Zweck der Information und Kommunikation in unserem sozialen Netzwerk verarbeitet.
              Dabei halten sich sowohl der AWO Unterbezirk als auch die Link IT isi GmbH an die
              gesetzlichen Grundlagen - insbesondere an die Anforderungen der europäischen
              Datenschutzgrundverordnung (DSGVO).
            </p>
            <br />
            <p>
              In dieser Datenschutzinformation informieren wir Sie über die Verarbeitung Ihrer
              personenbezogenen Daten und über Ihre Rechte im Rahmen der Nutzung von AWO Connect.
              Das Netzwerk AWO Connect wird in zertifizierten deutschen Rechenzentren betrieben.
            </p>
            <br />
            <p>
              Die Nutzung und Teilnahme an AWO Connect erfolgt ausschließlich aufgrund Ihrer
              Einwilligung.
            </p>
            <br />
            <b style={{ fontSize: '13pt' }}>
              Information zur Verarbeitung von personenbezogenen Daten gemäß des Artikels 13 DSGVO
            </b>
            <br />
            <br />
            <b style={{ fontSize: '12pt' }}>Verantwortliche Stelle</b>
            <br />
            <br />
            <p>
              Die verantwortliche Stelle für die nachfolgend beschriebenen Datenverarbeitungen ist
              (siehe auch <a href='/auth-imprint'>Impressum</a>):
            </p>
            <p>Arbeiterwohlfahrt Unterbezirk Münsterland-Recklinghausen</p>
            <p>Clemensstraße 2-4</p>
            <p>45699 Herten</p>
            <br />
            <p>Telefon: 02366 10910</p>
            <p>
              E-Mail: <a href='mailto: info@awo-msl-re.de'>info@awo-msl-re.de</a>
            </p>
            <br />
            <p>
              Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder
              gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
              personenbezogenen Daten (zum Beispiel Namen, E-Mail-Adressen) entscheidet.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Datenschutzbeauftragte</b>
            </p>
            <br />
            <p>Wir haben für unser Unternehmen eine Datenschutzbeauftragte bestellt.</p>
            <br />
            <p>Claudia Walkling</p>
            <p>Clemensstraße 2-4</p>
            <p>45699 Herten</p>
            <br />
            <p>Telefon: 02366 109175</p>
            <p>
              E-Mail: <a href='mailto: dsb@awo-msl-re.de'>dsb@awo-msl-re.de</a>
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Arten personenbezogener Daten</b>
            </p>
            <br />
            <p>
              Wir verarbeiten Ihren Namen, Ihre E-Mail-Adresse und die AWO-Einrichtung, deren
              Dienstleistung Sie in Anspruch nehmen, sowie personenbezogene Daten, die Sie selber in
              AWO Connect hinterlegen und angeben (z.B. Profildaten, Kontaktdaten, Foto-,
              Videoaufnahmen, Kommunikationsinhalte, Gesundheitsdaten), und personenbezogene Daten,
              die systembedingt durch Betriebssysteme und Geräte verarbeitet werden (z.B.
              IP-Adresse, Geräte-Kennung, Betriebssystem).
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Verwendungszweck</b>
            </p>
            <br />
            <p>
              Ihre E-Mail-Adresse wird verwendet, um den Zugang zu AWO Connect einzurichten und zu
              betreiben. AWO Connect nutzt die E-Mail-Adresse, um Ihnen Nachrichten zu schicken, Sie
              als Nutzer*in zu authentifizieren oder Ihr Kennwort zurückzusetzen. Ihren Vor- und
              Nachnamen nutzen wir, um Sie als Ersteller*in eines Beitrages in AWO Connect an den
              AWO Unterbezirk oder an andere Kund*innen zu nennen.
            </p>
            <br />
            <p>
              Ihre personenbezogenen Daten, die Sie selber in AWO Connect hinterlegen und angeben,
              werden im Rahmen Ihrer persönlichen Darstellung und Information und bei der
              Kommunikation im sozialen Netzwerk mit anderen Teilnehmer*innen verarbeitet. Sie
              können zu jedem Zeitpunkt Ihre Daten wieder löschen. Die Eingabe dieser Daten ist
              freiwillig.
            </p>
            <br />
            <p>
              Ihre personenbezogenen Daten, die systembedingt durch Betriebssystem und Geräte bei
              der Nutzung von AWO Connect verarbeitet werden, sind für den technischen Betrieb von
              AWO Connect erforderlich. Eine Weiterverarbeitung durch den AWO Unterbezirk oder die
              Link IT isi GmbH findet nicht statt.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Rechtsgrundlage</b>
            </p>
            <br />
            <p>
              Die Daten werden ausschließlich für und im Rahmen der Nutzung des sozialen Netzwerks
              AWO Connect erstellt und verarbeitet. Die Verarbeitung erfolgt auf der Grundlage Ihrer
              Einwilligung (Artikel 6 Absatz 1 Buchstabe a DSGVO).
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Empfänger Ihrer Daten</b>
            </p>
            <br />
            <p>
              Zur Erfüllung des Zwecks werden die Daten an folgende Empfänger bzw. Kategorien von
              Empfängern weitergegeben: Link IT isi GmbH (Betreiber des sozialen Netzwerks AWO
              Connect), Betriebssystem- und Geräte-Hersteller (je nachdem welches Betriebssystem und
              welches Gerät Sie nutzen)
            </p>
            <br />
            <p>
              Wir haben mit der Link IT isi GmbH einen Vertrag zur Auftragsverarbeitung gemäß
              Artikel 28 DSGVO abgeschlossen. Die Datenschutzinformationen der Link IT isi GmbH
              finden Sie <a href='https://www.link-it-isi.de/datenschutz'>hier</a>.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Speicherdauer Ihrer Daten</b>
            </p>
            <br />
            <p>
              Sie haben jederzeit die Möglichkeit, Ihre eingegebenen personenbezogenen Daten in AWO
              Connect zu löschen. Spätestens wenn Ihr Nutzerzugang gelöscht wird, werden alle Daten
              in AWO Connect gelöscht.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Ihre Rechte (nach Artikel 15 bis 21 DSGVO)</b>
            </p>
            <br />
            <p>
              Ihre Einwilligung ist selbstverständlich freiwillig und kann jederzeit mit Wirkung für
              die Zukunft ganz oder teilweise widerrufen werden. Wenn Sie die Einwilligung nicht
              erteilen möchten, wird dies keinerlei Konsequenzen für Ihre Teilnahme an der
              Dienstleistung unserer Einrichtung haben.
            </p>
            <br />
            <p>
              Im Falle eines Widerrufs werden wir die Daten nicht weiter in AWO Connect verarbeiten
              und Ihren Nutzerzugang und alle gespeicherten Daten löschen. Den Widerruf richten Sie
              bitte an die Datenschutzbeauftragte.
            </p>
            <br />
            <p>
              Werden Ihre personenbezogenen Daten verarbeitet, so besteht das Recht, Auskunft über
              die zu Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO). Sie können auch
              jederzeit selbständig Ihre gesamten Daten in AWO Connect herunterladen. Sollten
              unrichtige personenbezogene Daten verarbeitet werden, steht das Recht auf Berichtigung
              zu (Art. 16 DSGVO). Liegen die gesetzlichen Voraussetzungen vor, so kann die Löschung
              oder Einschränkung der Verarbeitung verlangt sowie Widerspruch gegen die Verarbeitung
              eingelegt werden (Art. 17, 18 und 21 DSGVO). Mit Einwilligung in die Datenverarbeitung
              und wenn die Datenverarbeitung mithilfe automatisierter Verfahren durchgeführt wird,
              steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu (Art. 20 DSGVO).
            </p>
            <br />
            <p>
              Sie haben das Recht sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht
              sind, dass die Verarbeitung Ihrer personenbezogenen Daten rechtswidrig erfolgt.
            </p>
            <br />
            <p>
              <b style={{ fontSize: '12pt' }}>Verpflichtung zur Bereitstellung der Daten</b>
            </p>
            <br />
            <p>
              Ohne Angabe Ihrer personenbezogenen Daten (Name, E-Mail-Adresse) kann die Nutzung des
              sozialen Netzwerks AWO Connect nicht angeboten werden.
            </p>
            <br />
          </div>
        </Row>
      </Col>
    </Wrapper>
  );

  const content = (
    <Wrapper>
      {!isLoggedIn && (
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <AppLogo />
        </div>
      )}

      {!isLoggedIn && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
          <Button onClick={() => history.goBack()}>
            <i className='fa fa-arrow-left' style={{ marginRight: 5 }} /> {t('Privacy.Go Back')}
          </Button>
        </div>
      )}

      {isEditPopupOpened && (
        <CreateKita closePopup={() => { setIsEditPopupOpened(false); window.location.reload(); }} isEditing fields={currentKita} />
      )}

      <Col>
        <Row>
          <div
            style={{
              background: '#fff',
              padding: '30px',
              boxShadow: !isLoggedIn && '0 0 15px #627d92',
              margin: !isLoggedIn && '50px 0',
            }}
          >
            {isLoggedIn && isAdmin && (
              <Card className={styles.container}>
                <Checkbox
                  className={styles.checkbox}
                  name={t('DataProtection.Use the privacy decleration of Stramplerbande')}
                  onChange={() => {
                    setOption('stand');
                    createDPP({ setting: 'stand' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'stand'}
                />
                <Checkbox
                  className={styles.checkbox}
                  name={t('DataProtection.Use your own privacy decleration')}
                  onChange={() => {
                    setOption('own');
                    createDPP({ setting: 'own' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'own'}
                />
                <Checkbox
                  className={styles.checkbox}
                  name={t(
                    'DataProtection.Use the privacy declaration of Stramplerbande and your own',
                  )}
                  onChange={() => {
                    setOption('both');
                    createDPP({ setting: 'both' });
                    store.dispatch(
                      showBottomNotification(t('BottomNotifications.Saved'), {
                        isFail: false,
                      }),
                    );
                  }
                  }
                  isChecked={option === 'both'}
                />
              </Card>
            )}
            <h2>
              {t('DataProtection.DataProtection')} {isLoggedIn && activeKita.kitaName}
            </h2>
            {option === 'stand' && (
              <React.Fragment>
                <div>{stramplerbandeDPP}</div>
              </React.Fragment>
            )}
            {option === 'own' && isAdmin && (
              <React.Fragment>
                <DataProtectionFeed
                  text={dPP.text}
                  setFunction={setDPP}
                  option={dPP.setting}
                  type='dPP'
                />
                {isAwoWW() && (
                  <ProfileGdpr />)}
              </React.Fragment>
            )}
            {option === 'own' && !isAdmin && (
              <React.Fragment>
                <p dangerouslySetInnerHTML={{ __html: sanitize(dPP.text) }} />
              </React.Fragment>
            )}
            {option === 'both' && isAdmin && (
              <React.Fragment>
                <h3>{t('DataProtection.Data protection policy of the institution')}</h3>
                <br />
                <DataProtectionFeed
                  text={dPP.text}
                  setFunction={setDPP}
                  option={option}
                  type='dPP'
                />
                <br /> <hr /> <br />
                <div>{stramplerbandeDPP}</div>
              </React.Fragment>
            )}
            {option === 'both' && !isAdmin && (
              <React.Fragment>
                <h3>{t('DataProtection.Data protection policy of the institution')}</h3>
                <br />
                <p dangerouslySetInnerHTML={{ __html: sanitize(dPP.text) }} />
                <br /> <hr /> <br />
                <div>{stramplerbandeDPP}</div>
              </React.Fragment>
            )}
          </div>
        </Row>
      </Col>

      {!isLoggedIn && (
        <div
          style={{
            margin: '0 0 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link
            style={{
              color: '#b8c7d3',
              marginRight: '10px',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
            to='/auth-imprint'
          >
            Imprint
          </Link>
          <Link
            style={{
              color: '#b8c7d3',
              fontSize: '12px',
              textDecoration: 'underline',
            }}
            to='/auth-privacy'
          >
            Privacy
          </Link>
        </div>
      )}
    </Wrapper>
  );

  return isLoggedIn ? (
    !isLoading ? (
      <KitaPanelWrapper>{content}</KitaPanelWrapper>
    ) : (
      <Loader />
    )
  ) : window.location.hostname.includes('awobamberg.safe2connect.org') ? (
    awobambergGDPR
  ) : window.location.hostname.includes('indag.safe2connect.org') ? (
    indagGDPR
  ) : window.location.hostname.includes('awoconnect.safe2connect.org') ? (
    awoconnectGDPR
  ) : (
    content
  );
}
