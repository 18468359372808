import axios from 'library/api/axios';
import config, { getPort } from 'main/config';

export const URL = `${config.API_BASE_URI}${getPort(8080)}/api`;

//employee checkInOut ----------------------

export const getEmployeeCheckInOut = () => axios.get(`${URL}/employeeCheckInOut`);

export const getEmployeeCheckInOutInTimespan = obj =>
  axios.post(`${URL}/employeeCheckInOut/timespan`, null, { params: obj });

export const getEmployeeCheckInOutBreak = () => axios.get(`${URL}/employeeCheckInOut/break`);

export const checkInOut = checkInOutData => axios.post(`${URL}/employeeCheckInOut`, checkInOutData);

export const getCheckInOutDetails = userID => axios.get(`${URL}/getEmployeeCheckInOut/${userID}`);

export const manualCheckInOut = checkInOutArray =>
  axios.post(`${URL}/employeeCheckInOut/manual`, checkInOutArray);

export const reviewManualEmployeeCheckInOut = checkInOut =>
  axios.post(`${URL}/employeeCheckInOut/review`, checkInOut);

export const getUnapprovedCheckInOuts = () => axios.get(`${URL}/employeeCheckInOut/unapproved`);

// last 30 checkInOut
export const getEmployeeCheckInOutHistory = userId =>
  axios.get(`${URL}/employeeCheckInOut/history/${userId}`);

export const getEmployeeFullCheckInOutHistory = userId =>
  axios.get(`${URL}/employeeCheckInOut/fullHistory/${userId}`);

export const getEmployeeCheckInOutHistoryForKita = kitaId =>
  axios.get(`${URL}//kitas/${kitaId}/employeeCheckInOut`);

export const updateCheckInOutTime = updatedCheckInOut =>
  axios.post(`${URL}/employeeCheckInOut/update`, updatedCheckInOut);

// employee absence ----------------------

export const getEmployeeAbsences = kitaId =>
  axios.get(`${URL}/employeeAbsence/`, { params: { kitaId } });

export const postEmployeeAbsence = data => axios.post(`${URL}/employeeAbsence/`, data);

export const reviewEmployeeAbsence = (absenceId, status) =>
  axios.post(`${URL}/employeeAbsence/${absenceId}/${status}`);

export const deleteEmployeeAbsence = absenceId =>
  axios.delete(`${URL}/deleteEmployeeAbsence/${absenceId}`);

export const getEmployeeAbsencesByStatus = (status, kitaId) =>
  axios.get(`${URL}/employeeAbsence/${status}`, { params: { kitaId } });

export const updateEmployeeAbsence = (absenceId, absenceUpdates) =>
  axios.post(`${URL}/employeeAbsence/edit/${absenceId}`, absenceUpdates);

// employee information ----------------------

export const getAllEmployeeInformation = () => axios.get(`${URL}/employeeInformation/`);

export const getAllEmployeeWorkingHours = () =>
  axios.get(`${URL}/employeeInformation/workingHours/`);

export const getAllEmployeeWorkingHoursByGroupId = groupId =>
  axios.get(`${URL}/employeeInformation/workingHours/${groupId}`);

export const updateEmployeeWorkingHoursByUserId = (userId, startTime, endTime, date) =>
  axios.put(`${URL}/employeeInformation/update-working-hour/${userId}`, {
    startTime,
    endTime,
    dateOfWeek: date,
  });

export const getEmployeeInformation = employeeUserId =>
  axios.get(`${URL}/employeeInformation/${employeeUserId}`);

export const postEmployeeInformation = data =>
  axios.post(`${URL}/employeeInformation/`, null, { params: data });

export const updateEmployeeInformation = data =>
  axios.post(`${URL}/employeeInformation/update`, data);

// employee kita absence types ----------------------

export const updateEmployeeKitaAbsenceTypes = data =>
  axios.put(`${URL}/employeeInformation/update-kita-absence-types`, data);

export const getEmployeeKitaAbsenceSettings = () =>
  axios.get(`${URL}/employeeInformation/absence-settings`);

export const getEmployeeKitaAbsenceTypes = () =>
  axios.get(`${URL}/employeeInformation/kita-absence-types`);

export const getEmployeeInfoHistory = userId =>
  axios.get(`${URL}/employeeInformation/history/overtime/${userId}`);

export const getEmployeeInfoOvertimeHistory = userId =>
  axios.get(`${URL}/employeeInformation/history/overtime/${userId}`);

// employee working day ----------------------

export const getEmployeeWorkingDaysInTimespan = obj =>
  axios.post(`${URL}/employeeWorkingDay/timespan`, null, { params: obj });

export const getRecentEmployeeWorkingDays = obj =>
  axios.post(`${URL}/employeeWorkingDay/recent`, null, { params: obj });
