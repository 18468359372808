import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeActivate2FA, getActivate2FA } from 'library/api/modules';
import CheckBox from 'library/common/commonComponents/Checkbox';
import EmailVerification from 'library/common/commonComponents/EmailVerification';
import {
  shouldShowEmailVerification,
  generatePublicKeyCredential,
} from 'library/common/commonComponents/FingerprintVerification/util';

import styles from './authentication2fa.module.scss';
import { editConfig, getConfig } from 'library/api/kita';

function Authentication2FA() {
  const { t } = useTranslation();
  const [active2FA, setActive2FA] = useState(false);
  const [displayEmailDialog, setDisplayEmailDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastLogin, setLastLogin] = useState(false);
  const [signature, setSignature] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [receivePrivateMessage, setReceivePrivateMessage] = useState(false);
  const [groupMandatoryField, setGroupMandatoryField] = useState(false);
  const [allowParentsAddingChild, setAllowParentsAddingChild] = useState(false);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = (await getActivate2FA()).data;
      setActive2FA(res);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    getConfig().then(res => {
      const data = res.data;
      setLastLogin(data[0]);
      setSignature(data[1]);
      setCommunication(data[2]);
      setReceivePrivateMessage(data[3]);
      setGroupMandatoryField(data[4]);
      setAllowParentsAddingChild(data[5]);
    });
  }, []);

  const handle2Fa = async () => {
    if (await shouldShowEmailVerification()) {
      setDisplayEmailDialog(true);
    } else {
      try {
        await generatePublicKeyCredential();
        setIsLoading(true);
        const res = (await changeActivate2FA(active2FA ? 'DEACTIVATE' : 'ENABLE')).data;
        if (res) {
          setActive2FA(prevState => !prevState);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const changeConfig = (config, checkedConfig) => {
    editConfig(config).then(res => {
      if (checkedConfig == 0) {
        setLastLogin(!lastLogin);
      } else if (checkedConfig == 1) {
        setSignature(!signature);
      } else if (checkedConfig == 2) {
        setCommunication(!communication);
      } else if (checkedConfig == 3){
        setReceivePrivateMessage(!receivePrivateMessage);
      } else if (checkedConfig == 4){
        setGroupMandatoryField(!groupMandatoryField);
      } else if (checkedConfig == 5){
        setAllowParentsAddingChild(!allowParentsAddingChild);
      }
      setRefresh(!refresh);
    });
  };

  const handle2faWithEmailDialog = isSuccess => {
    if (isSuccess) {
      setIsLoading(true);
      changeActivate2FA(active2FA ? 'DEACTIVATE' : 'ENABLE')
        .then(res => {
          if (res.data) {
            setActive2FA(prevState => !prevState);
          }
          setIsLoading(false);
          setDisplayEmailDialog(false);
        })
        .catch(() => {
          setIsLoading(false);
          setDisplayEmailDialog(false);
        });
    } else {
      setDisplayEmailDialog(false);
    }
  };
  return (
    <div className={styles.form2faGroups}>
      <div className={styles.form2faLabel}>{t('GeneralSetting.Authentication2FA')}</div>
      <CheckBox
        className={styles.checkboxSpace}
        name={t('GeneralSetting.Activate two factor auth')}
        isChecked={active2FA}
        isLoading={isLoading}
        onChange={handle2Fa}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Last Login')}
        isChecked={lastLogin}
        isLoading={isLoading}
        onChange={() => changeConfig('lastLogin', 0)}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Signature Tab')}
        isChecked={signature}
        isLoading={isLoading}
        onChange={() => changeConfig('signature', 1)}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Communication')}
        isChecked={communication}
        isLoading={isLoading}
        onChange={() => changeConfig('communication', 2)}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.Enable Receive Private Message')}
        isChecked={receivePrivateMessage}
        isLoading={isLoading}
        onChange={() => changeConfig('privateMessage', 3)}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('Administration.EnableGroupMandatoryField')}
        isChecked={groupMandatoryField}
        isLoading={isLoading}
        onChange={() => changeConfig('groupMandatoryField', 4)}
      />
      <CheckBox
        className={styles.checkboxSpace}
        name={t('AllowParentsAddingChild')}
        isChecked={allowParentsAddingChild}
        isLoading={isLoading}
        onChange={() => changeConfig('allowParentsAddingChild', 5)}
      />
      {displayEmailDialog && (
        <EmailVerification
          forSetting={displayEmailDialog}
          handle2FAWithEmailDialog={handle2faWithEmailDialog}
        />
      )}
    </div>
  );
}

export default Authentication2FA;
