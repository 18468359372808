import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import Col from 'library/common/commonComponents/Col';
import Row from 'library/common/commonComponents/Row';
import Feed from 'library/common/commonComponents/Feed/FeedContainer';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';

import styles from './dashboard.module.scss';
import { isPublicUser } from 'library/utilities/user';

export default function Dashboard() {
  useSiteTitle('Dashboard');
  const user = useSelector(state => state.userReducer);
  const location = useLocation();
  const [selectedKitaIds, setSelectedKitaIds] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedKitas')) || [];
  });
  const [selectedGroupIds, setSelectedGroupIds] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedGroups')) || [];
  });

  useEffect(() => {
    localStorage.removeItem('selectedKitas');
    localStorage.removeItem('selectedGroups');
  }, []);

  const [isWrongCodeShown, setIsWrongCodeShown] = useState(false);
  window.setIsWrongCodeShown = setIsWrongCodeShown;

  if (user.lastName === 'Caterer') {
    return (
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
        Bitte wählen Sie eine Gruppe aus
      </div>
    );
  }

  return (
    <KitaPanelWrapper>
      {isWrongCodeShown && window.qwe.asd}

      <Wrapper>
        <Col>
          <Row>
            <div className={styles.container}>
              <div className={styles.contentSide}>
                {/* Pass selectedIds to Feed or any other component as needed */}
                <Feed isOnDashboard isInPublicPage={isPublicUser(user)} selectedKitas={selectedKitaIds} selectedGroups={selectedGroupIds}/>
              </div>
            </div>
          </Row>
        </Col>
      </Wrapper>
    </KitaPanelWrapper>
  );
}
