import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { ACCESS_SCOPES } from 'main/msal';
import { updateUserGoogleLanguage } from 'library/api/settings';
import { isAwoWW } from 'library/api/tenantConfig';
import { showBottomNotification } from 'library/common/commonActions/notificationsActions';
import { changeWatsonLanguage } from 'library/common/commonActions/userActions';
import { checkExistingMsAccount } from 'library/api/user';
import AppLogo from 'library/common/commonComponents/AppLogo';
import Card from 'library/common/commonComponents/to-refactor/commonCard';
import Input from 'library/common/commonComponents/to-refactor/commonInput';
import CardHeading from 'library/common/commonComponents/to-refactor/commonCard/CardHeading';
import ButtonWithLoader from 'library/common/commonComponents/Buttons/ButtonWithLoader';
import InputErrorMsg from 'library/common/commonComponents/to-refactor/commonInputErrorMsg';
import CardWrapper from 'library/common/commonComponents/to-refactor/commonCardWrapper';
import CheckBox from 'library/common/commonComponents/Checkbox';
import { mapStaticLangToWatsonLang } from 'library/common/commonHooks/useLanguages';
import Storage from 'library/utilities/storage';
import { store } from 'main/store/configureStore';
import CookiesMessage from './frames/CookiesMessage';
import MicrosoftLoginButton from 'library/common/commonComponents/Buttons/MicrosoftLoginButton';

import styles from './index.module.scss';

function LoginFrame(props) {
  const { instance } = useMsal();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [msAccountError, setMsAccountError] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBounceAnimation, setIsBounceAnimation] = useState(true);
  const [isInputError, setIsInputError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const clientId = instance?.config?.auth?.clientId;

  const { t } = useTranslation();

  const stateHandlers = {
    email: setEmail,
    password: setPassword,
    emailError: setEmailError,
    passwordError: setPasswordError,
    isDisabled: setIsDisabled,
    isBounceAnimation: setIsBounceAnimation,
    isInputError: setIsInputError,
    isChecked: setIsChecked,
  };

  const setDisabledFlag = () => {
    setIsDisabled(false);
    setIsInputError(false);
  };

  const propsObject = {
    email,
    password,
    setErrorOnInput,
    loginService: props.loginService,
    language: Storage.getItem('locale'),
    setEmailError,
    setPasswordError,
    setIsInputError,
    setDisabledFlag,
    setIsDisabled,
    setIsBounceAnimation,
    t,
    isConfirm,
    isChecked,
    showBottomNotification: props.showBottomNotification,
    msLoginService: props.msLoginService,
    setMsAccountError,
  };
  function setErrorOnInput() {
    return setErrorOnInputHandler(propsObject);
  }
  const login = () => loginHandler(propsObject);

  const handleInputChange = event =>
    handleInputChangeHandler({ event, setEmailError, setPasswordError, stateHandlers });

  const onSubmit = !isDisabled ? login : undefined;

  const [isCookiesMessageVisible, setIsCookiesMessageVisible] = useState(false);
  useEffect(() => {
    if (isChecked) {
      setIsCookiesMessageVisible(true);
    }
  }, [isChecked]);

  const handleConfirmButton = () => {
    setIsConfirm(true);
    setIsCookiesMessageVisible(false);
  };

  const history = useHistory();
  if (!isDisabled && isAwoWW() && history.location.state?.autologin === true) {
    propsObject.email = 'StandardUserAWO@yopmail.com';
    propsObject.password = 'ju27gw63lm';
    login();
  }

  const msalRequest = () => {
    instance
      .loginPopup({
        scopes: ACCESS_SCOPES,
      })
      .then(async data => {
        const username = data.account.username;
        const res = await checkExistingMsAccount(username);

        if (!res.data) {
          setMsAccountError(t('Login.User does not exist'));
          return;
        }

        await msLoginHandler({
          ...propsObject,
          idToken: data.idToken,
          loginService: propsObject.msLoginService,
        });
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <CardWrapper>
      <div className={styles.logo}>
        <AppLogo isLoginPage />
      </div>

      <Card
        className={cn(
          styles.login_container,
          'animated',
          { bounceIn: isBounceAnimation },
          { shake: isInputError },
        )}
      >
        <CardHeading className={styles.login_heading_padding_15}>
          <strong>{`${t('Login.Please')} `}</strong>
          <span className={styles.login_heading_text_sign_in}>{t('Login.sign in')}</span>
        </CardHeading>
        <CardHeading className={styles.login_heading_2}>
          <span>{t("Login.If you're already a member")}</span>
        </CardHeading>
        <form onSubmit={login} className={styles.formGroup}>
          <div className={styles.formGroup}>
            <Input
              className={styles.email_form}
              placeholder={t('Login.username or email')}
              name='email'
              error={emailError !== ''}
              value={email}
              type='text'
              onChange={handleInputChange}
              onEnterDown={onSubmit}
            />
            {emailError !== '' && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={emailError} />
              </div>
            )}
          </div>
          <div className={styles.formGroup}>
            <Input
              className={styles.email_password}
              placeholder={t('Login.password')}
              value={password}
              error={passwordError !== ''}
              name='password'
              type='password'
              onChange={handleInputChange}
              onEnterDown={onSubmit}
            />
            {passwordError !== '' && (
              <div className={styles.error_msg}>
                <InputErrorMsg errorMsg={passwordError} />
              </div>
            )}
          </div>
        </form>
        <CheckBox
          onChange={() =>
            handleCheckBoxChange(() => {
              props.handleCheckBoxChange(!isChecked);
              setIsChecked(!isChecked);
            })
          }
          isChecked={isChecked}
          name={t('Login.Remember me')}
        />
        <div className={styles.stayLoginNotification}>
          {t('Login.RemainLoggedInForPushNotifications')}
        </div>
        {isCookiesMessageVisible && (
          <CookiesMessage
            cancel={() => {
              props.handleCheckBoxChange(!isChecked);
              setIsChecked(!isChecked);
              setIsCookiesMessageVisible(false);
            }}
            confirm={handleConfirmButton}
          />
        )}
        <hr className={styles.horizontal_line} />
        <div className={styles.sign_btn_container}>
          <ButtonWithLoader
            className={cn(styles.sign_btn_container_btn, { [styles.noMsSso]: !clientId })}
            type='primary'
            onClick={!isDisabled ? login : undefined}
            isLoading={isDisabled}
          >
            {t('Login.Sign in')}
          </ButtonWithLoader>

          {clientId && <MicrosoftLoginButton onClick={msalRequest} />}

          {msAccountError !== '' && (
            <div className={styles.ms_error_msg}>
              <InputErrorMsg errorMsg={msAccountError} />
            </div>
          )}

          <Link
            to='/password-recovery'
            className={cn(styles.forget_password_text, { [styles.noMsSso]: !clientId })}
          >
            {t('Login.Forgot your password?')}
          </Link>
        </div>
      </Card>
    </CardWrapper>
  );
}

export const handleCheckBoxChange = cb => cb();

export const loginHandler = ({
  email,
  password,
  setErrorOnInput,
  loginService,
  language,
  setEmailError,
  setPasswordError,
  setIsInputError,
  setDisabledFlag,
  setIsDisabled,
  t,
  isConfirm,
  isChecked,
  showBottomNotification: showBottomNotificationProps,
}) => {
  if (setErrorOnInput(email, password)) {
    if (isConfirm && isChecked) {
      Storage.setItem('rememberMe', true);
    }

    loginService({ email, password, language }).then(
      ({ status, isPendingRegistration, message }) => {
        if (status === 200) {
          setEmailError('');
          setPasswordError('');
          setIsInputError(false);
          setIsDisabled(false);

          const currentTime = new Date().getTime();
          Storage.setItem('loginTime', currentTime);

          const selectedLanguage = mapStaticLangToWatsonLang(Storage.getItem('locale'));
          if (selectedLanguage) {
            updateUserGoogleLanguage(selectedLanguage);
            store.dispatch(changeWatsonLanguage(selectedLanguage));
          }
        } else if (isPendingRegistration) {
          setEmailError('');
          setDisabledFlag();
          showBottomNotificationProps(t(message), {
            isFail: true,
          });
        } else {
          setEmailError('');
          setIsInputError(true);
          setDisabledFlag();
          setPasswordError(t('Login.User or Password incorrect'));
        }
      },
    );
  }
};

export const msLoginHandler = async ({
  email,
  idToken,
  loginService,
  setMsAccountError,
  language,
  setIsInputError,
  setDisabledFlag,
  setIsDisabled,
  t,
  showBottomNotification: showBottomNotificationProps,
}) => {
  const { status, isPendingRegistration, message } = await loginService({
    email,
    language,
    idToken,
  });
  if (status === 200) {
    setMsAccountError('');
    setIsInputError(false);
    setIsDisabled(false);

    const currentTime = new Date().getTime();
    Storage.setItem('loginTime', currentTime);

    const selectedLanguage = mapStaticLangToWatsonLang(Storage.getItem('locale'));
    if (selectedLanguage) {
      updateUserGoogleLanguage(selectedLanguage);
      store.dispatch(changeWatsonLanguage(selectedLanguage));
    }
  } else if (isPendingRegistration) {
    setMsAccountError('');
    setDisabledFlag();
    showBottomNotificationProps(t(message), {
      isFail: true,
    });
  } else {
    setIsInputError(true);
    setDisabledFlag();
    setMsAccountError(t('Login.User does not exist'));
  }
};

export const setErrorOnInputHandler = ({
  email,
  password,
  setEmailError,
  setPasswordError,
  setIsInputError,
  setIsDisabled,
  setIsBounceAnimation,
  setDisabledFlag,
  t,
}) => {
  if (email === '' && password === '') {
    setEmailError(t('Login.Username or email cannot be blank'));
    setPasswordError(t('Login.Password cannot be blank'));
    setIsInputError(true);
    setIsDisabled(true);
    setIsBounceAnimation(false);
    setDisabledFlag();
    return false;
  }
  if (password === '' && email !== '') {
    setEmailError('');
    setPasswordError(t('Login.Password cannot be blank'));
    setIsInputError(true);
    setIsBounceAnimation(false);
    setDisabledFlag();
    return false;
  }
  if (password !== '' && email === '') {
    setEmailError(t('Login.Username or email cannot be blank'));
    setPasswordError('');
    setIsInputError(true);
    setIsBounceAnimation(false);
    setDisabledFlag();
    return false;
  }
  setIsDisabled(true);
  setIsInputError(false);
  setIsBounceAnimation(false);
  return true;
};

export const handleInputChangeHandler = ({
  event,
  setEmailError,
  setPasswordError,
  stateHandlers,
}) => {
  setEmailError('');
  setPasswordError('');
  event.preventDefault();
  const { name, value } = event.target;
  stateHandlers[name](value);
};

const mapStateToProps = state => ({
  login: state.authorizationReducer,
  inputErrorState: state.inputErrorReducer,
  disableButtonState: state.buttonDisableReducer,
});

export default connect(
  mapStateToProps,
  { showBottomNotification },
)(LoginFrame);
