import React from 'react';
import { useTranslation } from 'react-i18next';

import Tabs from 'library/common/commonComponents/Tabs';
import Loader from 'library/common/commonComponents/Loader';
import UserEditing from 'library/common/commonComponents/UserEditing/UserEditingContainer';
import useProfileLoading from 'library/common/commonHooks/useProfileLoading';
import useSiteTitle from 'library/common/commonHooks/useSiteTitle';
import ProfileChangeEmail from './profileFrameFrames/ProfileChangeEmail';
import ProfileChangePassword from './profileFrameFrames/ProfileChangePassword';
import ProfileDeleteAccount from './profileFrameFrames/ProfileDeleteAccount';
import ProfileGdpr from './profileFrameFrames/ProfileGdpr';

import styles from './profileFrame.module.scss';
import { isAwoWW } from 'library/api/tenantConfig';

export default function ProfileFrame({ history, match, activeKita, showBottomNotification, user }) {
  useSiteTitle('AccountSettings');
  const { profileInfo, isLoading, setProfileInfo } = useProfileLoading({
    profileId: user.id,
    userId: user.id,
    match,
    history,
    showBottomNotification,
    isAccountSettingsPage: true,
    kitaId: activeKita.kitaId,
  });
  const { t } = useTranslation();
  const setEditingProfile = () => showBottomNotification(t('BottomNotifications.Saved'));

  const tabs = [
    {
      title: t('AccountSettings.Overview'),
      path: 'general',
      component: isLoading ? (
        <Loader />
      ) : (
        <UserEditing
          kitaId={activeKita.kitaId}
          editingProfile={profileInfo}
          setEditingProfile={setEditingProfile}
          history={history}
          activeKita={activeKita}
          isAdminEditing={user.administrationAccess || user.superAdminStatus}
        />
      ),
    },
    {
      title: t('AccountSettings.Change email'),
      path: 'change-email',
      component: (
        <ProfileChangeEmail
          profileInfo={profileInfo}
          showBottomNotification={showBottomNotification}
          setProfileInfo={setProfileInfo}
        />
      ),
    },
    {
      title: t('AccountSettings.Change password'),
      path: 'change-password',
      component: <ProfileChangePassword showBottomNotification={showBottomNotification} />,
    },
  ];

  if (!user.superAdminStatus) {
    tabs.push({
      title: t('AccountSettings.Delete account'),
      path: 'clear-account',
      component: <ProfileDeleteAccount userId={user.id} />,
    });
  }
  if (!isAwoWW()) {
    tabs.push({
      title: t('AccountSettings.Export activity'),
      path: 'gdpr',
      component: <ProfileGdpr />,
    });
  }

  const onTabChange = path => {
    history.push(`/account-settings/profile/${path}`);
  };

  return (
    <div className={styles.container}>
      <Tabs
        tabs={tabs}
        currentTab={match.params.tab ? match.params.tab : tabs[0].path}
        onChange={onTabChange}
      />
    </div>
  );
}
