import axios from 'library/api/axios';
import Storage from 'library/utilities/storage';
import config, { getPort } from 'main/config';
import { URL } from './kids';

export const getUserById = id =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user/${id}`, {
    params: {
      graphql:
        // eslint-disable-next-line
        'id,firstName,lastName,userName,email,about,hideYearInProfileStatus,orderFavorites,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode],deleted,lastAccess,deactivated,kitaDeactivationStatus,kitaRoles[roles[adminRole]],parent[kidsNames],registrationCreatedByFirstName,registrationCreatedByLastName,createdAt,timeZone[id,timeZone]',
    },
  });

export const getPendingKidsForCurrentKita = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/parent/pendingKidsForCurrentKita/`);

export const getUserByParentId = parentId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user/parent/${parentId}`);

export const getKitaUsers = async ({
  kitaId,
  page,
  sort,
  emailValue,
  invitedByValue,
  languageValue,
  createdAtValue,
  status,
  isPending,
}) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users`, {
    page,
    filter: getFilters({
      emailValue,
      invitedByValue,
      languageValue,
      createdAtValue,
      status,
      isPending,
    }),
    sort: [sort.name, sort.direction],
    graphql:
      // eslint-disable-next-line
      'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
  });

export const getKitaAdmins = (page, kitaId) => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/kitaAdmins`, {
    page,
    filter: [
      `registrationStatus:eq:registered`,
      'and',
      `deleted:eq:${false}`,
      'and',
      `deactivated:eq:${false}`,
    ],
    sort: ['user.lastName', 'asc'],
    graphql:
      // eslint-disable-next-line
      'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
  });
};

export const getKitaAwoMembers = (page, kitaId) => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/kitaAwoMembers`, {
    page,
    filter: [
      `registrationStatus:eq:registered`,
      'and',
      `deleted:eq:${false}`,
      'and',
      `deactivated:eq:${false}`,
    ],
    sort: ['user.lastName', 'asc'],
    graphql:
    // eslint-disable-next-line
      'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
  });
};

export const getKitaAdminsForReceivingPrivateMessage = (page, kitaId) => {
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/kitaadmins-receive-private-message`,
    {
      page,
      filter: [
        `registrationStatus:eq:registered`,
        'and',
        `deleted:eq:${false}`,
        'and',
        `deactivated:eq:${false}`,
      ],
      sort: ['user.lastName', 'asc'],
      graphql:
        // eslint-disable-next-line
        'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
    },
  );
};

export const getUsersTabCount = kitaId => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/user-tab-count/`, [
    {
      page: 1,
      filter: getFilters({
        emailValue: '',
        invitedByValue: '',
        languageValue: '',
        createdAtValue: '',
        status: false,
        isPending: false,
      }),
      sort: ['user.lastName', 'asc'],
      graphql:
        // eslint-disable-next-line
        'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
    },
    {
      page: 1,
      filter: getFilters({
        emailValue: '',
        invitedByValue: '',
        languageValue: '',
        createdAtValue: '',
        status: false,
        isPending: true,
      }),
      sort: ['user.lastName', 'asc'],
      graphql:
        // eslint-disable-next-line
        'id,user[profileLogoXCoordinate,profileLogoYCoordinate,profileLogoWidth,profileLogoAspect,id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode]],deleted,lastAccess,deactivated,kitaDeactivationStatus,employee,kitaRoles[roles[adminRole]],parent[kidsNames],createdAt,registrationCreatedByFirstName,registrationCreatedByLastName',
    },
  ]);
};

export const getKitaUserById = (kitaId, userId) => {
  return axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/user/${userId}`);
};

export const getKitaUsersId = (kitaId, getAllPages = true) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users`,
    {
      graphql:
        // eslint-disable-next-line
        'user[id,firstName,lastName],parent[kidsNames]',
    },
    { params: { getAllPages } },
  );

export function getFilters({
  emailValue,
  invitedByValue,
  languageValue,
  createdAtValue,
  status,
  isPending,
}) {
  const filters = [
    `registrationStatus:eq:${isPending ? 'pending' : 'registered'}`,
    'and',
    `deleted:eq:${status}`,
    'and',
    `deactivated:eq:${status}`,
  ];
  if (emailValue) {
    filters.push('and', `searchKeyword:cic:${emailValue}`);
  }
  if (invitedByValue) {
    filters.push('and', `invitedBy:cic:${invitedByValue}`);
  }
  if (languageValue) {
    filters.push('and', `langCode:cic:${languageValue}`);
  }
  if (createdAtValue) {
    const formattedDate = getFormattedDate(new Date(createdAtValue));
    filters.push('and', `dateTime::${formattedDate}`);
  }

  return filters;
}

export const downloadExcel = ({
  type,
  kitaId,
  emailValue,
  invitedByValue,
  languageValue,
  createdAtValue,
  status,
  isPending,
  sort,
}) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/export?fileType=${type}`,
    {
      filter: getFilters({
        emailValue,
        invitedByValue,
        languageValue,
        createdAtValue,
        status: false,
        isPending,
      }),
      graphql:
        'id,kitaName,description,deleted,streetName,kitaType[id,kitaTypeTranslation[id,kitaTypeName]]',
      page: 1,
      size: 50,
      sort: [sort.name, sort.direction],
    },
    {
      headers: {
        'Accept-Language': Storage.getItem('locale'),
      },
      responseType: 'blob',
    },
  );

export const getCountries = () => axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/countries`);

export const resetPasswordForAllUsersTenant = () =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/user/resetPasswordForAllUsersTenant`);

export const deactivateUserInKita = (userId, deactivateFlag, deactivateAllOrActivateAllFlag) =>
  axios.put(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/user/${userId}/deactivate/${deactivateFlag}/${deactivateAllOrActivateAllFlag}`,
  );

export const resetPasswordForAllUsersTenantThatWereNotLoggedIn = () =>
  axios.put(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/user/resetPasswordForAllUsersTenantThatWereNotLoggedIn`,
  );

export const validateTokenAndChangeEmail = token =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/user/confirm-email/${token}`);

export const editKitaUser = ({ kitaId, userId, editingFields, isAdminEditing }) =>
  axios.put(
    isAdminEditing
      ? `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/${userId}`
      : `${config.API_BASE_URI}${getPort(8080)}/api/users/current/profile`,
    editingFields,
  );

export const resendInvitation = ({ kitaId, userId }) =>
  axios.put(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/${userId}/invitation`,
  );

export const deleteInvitation = ({ kitaId, userId }) =>
  axios.delete(
    `${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/${userId}/invitation`,
  );

export const getUserModules = userId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/current/users/${userId}`, {
    params: {
      graphql:
        // eslint-disable-next-line max-len
        'modules[moduleId,moduleName,moduleDescription,activeStatus,configureStatus,moduleEnableDisableStatus,moduleKey]',
    },
  });

export const changeModuleStatus = ({ moduleId, moduleStatus }) =>
  axios.put(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/current/module/${moduleId}/${moduleStatus}`,
  );

export const changeUserPassword = ({ oldPassword, newPassword }) =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/users/current/profile/password`, {
    oldPassword,
    newPassword,
  });

export const changeUserEmail = ({ email, password }) =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/users/current/profile/email`, {
    email,
    password,
  });

export const getGroupsBeforeDeletion = ({ userId }) =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/users/${userId}/delete/groups/check`, {
    params: {
      graphql:
        // eslint-disable-next-line
        'id,memberCount,groupName,colourCode,groupLogoAspect,logoUrl,groupLogoWidth,groupLogoXCoordinate,groupLogoYCoordinate',
    },
  });

export const getGroupsBeforeDeletionForAllKitas = ({ userId }) =>
  axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/${userId}/deleteForAll/groups/check`,
    {
      params: {
        graphql:
          // eslint-disable-next-line
          'id,memberCount,groupName,colourCode,groupLogoAspect,logoUrl,groupLogoWidth,groupLogoXCoordinate,groupLogoYCoordinate',
      },
    },
  );

export const deactivateKid = (kid, forceDeactive = false) =>
  axios.put(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/parent/kid/deactivate/${kid}?forceDeactive=${forceDeactive}`,
  );

export const updateLanguageTimezone = ({ languageId }) =>
  axios.put(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/current/language/${languageId}/timeZone/1`,
  );

export function getFormattedDate(date) {
  const year = date.getUTCFullYear();
  const month =
    date.getUTCMonth() + 1 > 9 ? date.getUTCMonth() + 1 : '0' + (date.getUTCMonth() + 1);
  const day = date.getUTCDate() > 9 ? date.getUTCDate() : '0' + date.getUTCDate();

  const hours = date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours();
  const minutes = date.getUTCMinutes() > 9 ? date.getUTCMinutes() : '0' + date.getUTCMinutes();
  const seconds = date.getUTCSeconds() > 9 ? date.getUTCSeconds() : '0' + date.getUTCSeconds();

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getUserProfile = userId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/current/users/${userId}`, {
    params: {
      graphql:
        // eslint-disable-next-line
        'userName,lastName,firstName,email,profileHeaderAspect,profileHeaderUrl,profileHeaderWidth,profileHeaderXCoordinate,profileHeaderYCoordinate,profileLogoAspect,profileLogoUrl,profileLogoWidth,profileLogoXCoordinate,isEmployee,profileLogoYCoordinate,modules[moduleId,moduleName,moduleDescription,activeStatus,configureStatus,moduleKey],groups[groupId,groupName,colorCode,groupHeaderAspect,headerUrl,groupHeaderWidth,groupHeaderXCoordinate,groupHeaderYCoordinate,groupLogoAspect,groupLogoUrl,groupLogoWidth,groupLogoXCoordinate,groupLogoYCoordinate],id,firstName,lastName,userName,email,about,hideYearInProfileStatus,birthday,city,country[id,countryName],email,fax,gender,mobile,phonePrivate,phoneWork,state,street,title,userName,zip,language[id,langCode],friendStatus[statusType,uniqueRequesterReceiverId,kitaId,requesterId,receiverId,lastModifiedById,id],superAdminStatus',
    },
  });

export const sendFriendRequest = (userId, kitaId) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/friends/request`,
    { userId },
    {
      headers: { 'kita-id': kitaId },
    },
  );

export const loadMoreUserGroups = (id, data, params) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/${id}/groups`,
    {
      ...data,
      sort: ['groupName', 'asc'],
      graphql:
        // eslint-disable-next-line
        'groupName,colourCode,groupDescription,groupLogoUrl,memberStatus,id,logoUrl,groupLogoAspect,groupLogoXCoordinate,groupLogoYCoordinate,groupLogoWidth,globalPublicGroup',
    },
    params,
  );

export const getGroupsByUserIdAndKitaId = (userId, kitaId) => {
  return axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/showgroups/${userId}/${kitaId}`,
  );
};

export const acceptFriendRequest = (requestId, kitaId) =>
  axios.patch(
    `${config.API_BASE_URI}${getPort(8080)}/api/friends/request/${requestId}`,
    {
      friendStatusType: 'ACCEPTED',
    },
    {
      headers: { 'kita-id': kitaId },
    },
  );

export const cancelFriendRequest = (requestId, kitaId) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/friends/request/${requestId}`, {
    headers: { 'kita-id': kitaId },
  });

export const unFriend = (requestId, kitaId) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/friends/request/${requestId}`, {
    headers: { 'kita-id': kitaId },
  });

export const uploadProfileImage = async ({
  image,
  imageAspect,
  imageWidth,
  type,
  setUploadProgress,
}) => {
  const uploadType = type === 'header' ? 'header' : 'logo';
  const formData = new FormData();
  formData.append('image', image);
  formData.append('imageAspect', imageAspect);
  formData.append('imageWidth', imageWidth);
  formData.append('uploadType', uploadType);
  formData.append('xCoordinate', 25);
  formData.append('yCoordinate', 25);
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/current/${uploadType}`,
    formData,
    {
      onUploadProgress: progressEvent => {
        setUploadProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total));
      },
    },
  );
};

export const editProfileImage = ({
  imageAspect,
  imageWidth,
  type,
  xCoordinate,
  yCoordinate,
  rotation,
}) => {
  const uploadType = type === 'header' ? 'header' : 'logo';
  const queryParams = {
    imageAspect,
    imageWidth: imageWidth || 50,
    xCoordinate: xCoordinate || 0,
    yCoordinate: yCoordinate || 0,
    rotation: rotation || 0,
  };
  return axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/users/current/${uploadType}`, null, {
    params: queryParams,
  });
};

export const deleteProfileImage = type => {
  const deleteType = type === 'header' ? 'header' : 'logo';
  return axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/users/current/${deleteType}`);
};

export const deleteProfileAccount = password =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/users/current/`, { data: { password } });

export const getLanguages = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/public/languages`);

export const getTimeZones = () => axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/timezones`);

export const deleteUserFromKita = ({ kitaId, userId }) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/${userId}`);

export const deleteAllUserFromKita = ({ kitaId, userId }) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/all-users/${userId}`);

export const deleteUserForAll = ({ userId }) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/users/deleteAll/${userId}`);

export const selectedUsersBulkDeletionFromKita = ({ userIds, kitaId }) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/users/bulkDelete`, {
    data: userIds,
  });

export const payloadOfGetPermissionsForUser = {
  method: 'get',
  url: `${config.API_BASE_URI}${getPort(8091)}/api/uploadPermissions`,
};

export const getPermissionsForUser = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8091)}/api/uploadPermissions`);

export const getUploadPermissions = ids =>
  axios.get(`${config.API_BASE_URI}${getPort(8091)}/api/uploadPermissions?userIds=${ids}`);

export const setUploadPermissions = payload =>
  axios.post(`${config.API_BASE_URI}${getPort(8091)}/api/uploadPermissions`, payload);

export const getFalsePermissionsUsers = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8091)}/api/falsePermissionsUsers`);

export const setUploadPermissionsForAll = payload =>
  axios.post(`${config.API_BASE_URI}${getPort(8091)}/api/uploadPermissionsAll`, payload);

export const importUsers = (formData, emailFlag) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/import/${emailFlag}`, formData);

export const importAdebis = (file, emailFlag) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/importadebis/${emailFlag}`, file);

export const importUsersToGroup = file =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/importuserstogroup`, file);

export const importMember = (file, emailFlag) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/importmember/${emailFlag}`, file);

export const importAdebisGfz = (file, emailFlag) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/importadebisgfz/${emailFlag}`,
    file,
  );

export const importAdebisId = file =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/importadebisidgfz`, file);

export const inviteMultipleUsersToMultipleKitas = (userEmails, kitaIds) => {
  const payload = {
    emails: userEmails,
    kitaIds,
  };
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/invite/multipleKitas`,
    payload,
  );
};

export const importWinKita = (file, emailFlag) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/importwinkita/${emailFlag}`, file);

export const importKitaPortal = (file, emailFlag) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/importkitaportal/${emailFlag}`,
    file,
  );

export const getChildrenByUserId = (includeNotApprovedKids = true, userId = null) =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/kids`, {
    params: {
      graphql:
        'id,firstName,lastName,gender,enableBirthdayNotification,street,zip,city,country,state,birthday,about,parent1Status,kidCode,kidGroup[id,groupName],parent1[firstName,lastName,userKita[userId]],parent2[firstName,lastName,userKita[userId]],foodOrdersPerWeek,participateInExcursionsAllowed,exchangeWithOtherInstitutionsAllowed,photosAllowed,showPhotosInInstitutionAllowed,usePhotosInPortfolioAllowed,usePhotosInOtherChildsPortfolioAllowed,usePhotosForAdvancedEducationAllowed,exchangeWithElementarySchoolAllowed,pickupBySiblingAllowed,noNDAWithDoctor,noNDAWithSpecialService,beeStingerRemovalForbidden,contactParentsAfterBeeStingerRemoval,beeStingerRemovalAllowed',
      includeNotApprovedKids,
      userId,
    },
  });

export const getPendingChildrenByUserId = id =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/parent/pendingKids/${id}`);

export const getFinalListOfPendingChildren = users => {
  return axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/pendingKidsFromUserIDList/${users}`,
  );
};

export const approveChild = (userId, kidId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/approve/${userId}/${kidId}`);

export const approveChildByKitaId = (userId, kidId, kitaId) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/approve/${userId}/${kidId}?kitaId=${kitaId}`,
  );

export const rejectChild = (userId, kidId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/reject/${userId}/${kidId}`);

export const hideKidNames = (id, value) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/setHideKidNames/${id}`, value);

export const popupKids = id =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/parent/popupKids/${id}`);

export const getKidAbsence = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/`);

export const getKidAbsenceByTimespan = (from, to) =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/timespan/${from}/${to}`);

export const getOwnKidAbsenceByTimespan = (from, to) =>
  axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/ownchilds/timespan/${from}/${to}`,
  );

export const getOwnKidAbsenceByTimespanAndGroup = (groupId, from, to) =>
  axios.get(
    `${config.API_BASE_URI}${getPort(
      8080,
    )}/api/kidAbsence/ownchilds/group/${groupId}/timespan/${from}/${to}`,
  );

export const getKidAbsenceByTimespanAndGroup = (groupId, from, to) =>
  axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/group/${groupId}/timespan/${from}/${to}`,
  );

export const getKidAbsenceHistoryForUser = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/parentKidAbsenceHistory/`);

export const getMemberAbsenceHistoryForUser = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/memberAbsenceHistory/`);

export const getKidGroupAbsence = groupId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidGroupAbsence/?groupId=${groupId}`);

export const getBirthdayEventByGroup = (groupId, from, to) =>
  axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/birthday-event/${groupId}/timespan/${from}/${to}`,
  );

export const getBirthdayEventByGroupKita = (from, to) =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/birthday-event/timespan/${from}/${to}`);

export const getMemberGroupAbsence = groupId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/memberGroupAbsence/?groupId=${groupId}`);

export const markKidAbsenceRead = absenceId =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/${absenceId}/read`);

export const markKidAbsenceReadToParent = (absenceId, shouldSendMessageToParents, symptom, time) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/${absenceId}/read`, {
    shouldSendMessageToParents,
    symptom,
    time,
  });

export const markMemberAbsenceRead = (absenceId, groupId) =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/memberAbsence/${absenceId}/read/${groupId}`,
  );

export const getUnreadKidAbsenceCount = groupId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/unreadCount?groupId=${groupId}`);

export const postKidAbsence = data =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/`, data);

export const postMemberAbsence = data =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/memberAbsence/`, data);

export const putKidAbsence = data =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/putKidAbsence/`, data);

export const putMemberAbsence = data =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/putMemberAbsence/`, data);

export const deleteKidAbsence = data =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/deleteKidAbsence/`, { data: data });

export const deleteKidAbsenceByTimeRange = data =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/deleteKidAbsenceByTimeRange`, {
    data: data,
  });

export const deleteMemberAbsence = data =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/deleteMemberAbsence/`, { data: data });

export const getPendingApprovals = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/waiting-for-approval`);

export const getAllPendingApprovals = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/all-waiting-for-approval`);

export const getActiveKitaAdmins = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/kita-admins`);

export const approvePendingUser = value =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/waiting-for-approval`, value);

export const allApprovePendingUser = (value, kitaId) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/${kitaId}/all-waiting-for-approval`, value);

export const approveAllPendingUsers = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/waiting-for-approval/all`);

export const approveAllPendingUsersAllKita = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/user-kita/all-waiting-for-approval/all`);

export const exportActivity = () =>
  axios.post(`${config.API_BASE_URI}${getPort(8091)}/api/userActivityExport/export`);

export const addKid = (kid, payload = {}) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/`, payload, { params: kid });

export const updateKid = (kid, payload) =>
  axios.put(`${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/${kid.id}`, payload, {
    params: kid,
  });

export const deleteKid = (kid, userid) =>
  axios.delete(`${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/${kid.id}/${userid}`);

export const getKidVerificationImage = kidId =>
  axios.get(`/parent/kid/${kidId}/verification-image`);

export const setKidVerificationImage = (kidId, verificationImageId) =>
  axios.post(`/parent/kid/${kidId}/verification-image/${verificationImageId}`);

export const getAbsenceNotificationsActiveAdmin = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/admin`);

export const getAbsenceNotificationsActiveEmployee = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/employee`);

export const setAbsenceNotificationsActiveAdmin = active =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/admin`,
    active,
  );

export const setAbsenceNotificationsActiveEmployee = active =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/employee`,
    active,
  );

export const getAbsenceNotificationsActiveParent = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/parent`);

export const setAbsenceNotificationsActiveToParent = active =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/kidAbsence/notificationsActive/parent`,
    active,
  );

export const postGfzConsent = async data => {
  const apiResult = await axios.post(URL + '/savegfzconsent', {
    ...data,
  });
};

export const postKitaConsent = async data => {
  const apiResult = await axios.post(URL + '/savekitaconsent', {
    ...data,
  });
};

export const postCaritasConsent = async data => {
  const apiResult = await axios.post(URL + '/savecaritasconsent', {
    ...data,
  });
};

export const postChristusErloeserConsent = async data => {
  const apiResult = await axios.post(URL + '/savechristuserloesersconsent', {
    ...data,
  });
};

export const sendPersonalMessageToDiakon = () => {
  return axios.post(URL + '/sendpersonalmessagetodiakon');
};

export const allChristusErloeserConsentsToExcel = () =>
  axios.get(`${URL}/allChristusErloeserConsentsToExcel`, { responseType: 'blob' });

export const allKitaKidListToExcel = kitaId =>
  axios.post(`${URL}/kitas/${kitaId}/kids/export`, {}, { responseType: 'blob' });

export const getGfzConsent = userId => {
  if (userId !== null) {
    return axios.get(`${URL}/loadgfzconsent/${userId}`);
  }
  return axios.get(`${URL}/loadgfzconsent`);
};

export const getCaritasConsent = userId => {
  if (userId !== null) {
    return axios.get(`${URL}/loadcaritasconsent/${userId}`);
  }
  return axios.get(`${URL}/loadcaritasconsent`);
};

export const getChristusErloeserConsent = userId => {
  if (userId !== null) {
    return axios.get(`${URL}/loadchristuserloeserconsent/${userId}`);
  }
  return axios.get(`${URL}/loadchristuserloeserconsent`);
};

export const getKitaConsent = userId => {
  if (userId !== null) {
    return axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/loadkitaconsent/${userId}`);
  }
  return axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/loadkitaconsent`);
};

export const sendEmail = (kidId, foodArray, type) => {
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/sendEmailonSubmitting/${kidId}/${type}`,
    foodArray,
  );
};

export const getAllKitaEmployee = kitaId =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/${kitaId}/employee`);

export const createEmployeePortfolioGroupIfNeeded = userId => {
  return axios.get(
    `${config.API_BASE_URI}${getPort(8080)}/api/users/createemployeeportfoliogroup/${userId}`,
  );
};

export const getAuthorizedPickupPersons = kidId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/authorized/${kidId}`);

export const createAuthorizedPickupPerson = (
  kidId,
  firstName,
  lastName,
  email,
  phonenumber,
  image,
) => {
  const formData = new FormData();
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('email', email);
  formData.append('phonenumber', phonenumber);

  if (image != null) {
    formData.append('image', image);
  }

  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/authorized/${kidId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const deleteAuthorizedPickupPerson = id => {
  return axios.delete(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/delete/authorized/${id}`,
  );
};

export const approveAuthorizedPickupPerson = id => {
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/parent/kid/approve/authorized/${id}`,
  );
};

export const updateAuthorizedPickupPerson = (
  kidId,
  firstName,
  lastName,
  email,
  phonenumber,
  image,
  origImage,
) => {
  const formData = new FormData();
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('email', email);
  formData.append('phonenumber', phonenumber);

  if (image != null) {
    formData.append('image', image);
  }
  formData.append('origImage', origImage || '');

  return axios.put(`/parent/kid/authorized/${kidId}`, formData);
};

export const getKidAbsenceAndHistoryDataForAbsenceCalendarByTimespanAndGroup = (
  groupId,
  from,
  to,
) => axios.get(`/kidAbsencesAndCheckinoutHistory/group/${groupId}/timespan/${from}/${to}`);

export const downloadKidAbsenceOverviewForGroup = (groupId, month) =>
  axios.post(
    `/kidAbsence/export/group/${groupId}/${month}`,
    {},
    {
      responseType: 'blob',
    },
  );

export const downloadKidAbsenceOverviewForChild = (childId, month) =>
  axios.post(
    `/kidAbsence/export/${childId}/${month}`,
    {},
    {
      responseType: 'blob',
    },
  );

export const downloadKidAbsenceOverviewForGroupPdf = (groupId, month) =>
  axios.post(
    `/kidAbsence/exportpdf/group/${groupId}/${month}`,
    {},
    {
      responseType: 'blob',
    },
  );

export const downloadKidAbsenceOverviewForChildPdf = (childId, month) =>
  axios.post(
    `/kidAbsence/exportpdf/${childId}/${month}`,
    {},
    {
      responseType: 'blob',
    },
  );

export const enableDisableCheckbox = payload => {
  return axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/enableDisableCheckboxCategory`,
    payload,
  );
};
export const saveEditAndDeleteCategory = payload => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/saveEditOrDeleteCategory`, payload);
};

export const getAllCategories = kitaID => {
  return axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/getCategoryData/${kitaID}`);
};

export const checkExistingMsAccount = userName => {
  return axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/user/check-ms-account/${userName}`);
};

export const uploadMultipleUserLogos = files => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/uploadMultipleUserLogos`, files);
};

export const downloadUserLogosAsZipFiles = () => {
  return axios
    .get(`${config.API_BASE_URI}${getPort(8080)}/api/downloadUserLogosAsZipFiles`, {
      responseType: 'blob',
    })
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `logos.zip`);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const uploadMultipleGroupLogos = files => {
  return axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/uploadMultipleGroupLogos`, files);
};

export const downloadGroupLogosAsZipFiles = () => {
  return axios
    .get(`${config.API_BASE_URI}${getPort(8080)}/api/downloadGroupLogosAsZipFiles`, {
      responseType: 'blob',
    })
    .then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `logos.zip`);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const setFoodOrderInformCancelation = (active, type) =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/foodorder/update-notifications`, {
    active,
    type,
  });

export const getFoodOrderInformCancelation = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/foodorder/cancel-notifications-config`);

export const setNoScreenshotsStatus = active =>
  axios.post(`${config.API_BASE_URI}${getPort(8080)}/api/images/no-screenshots`, active);

export const getNoScreenshotsStatus = () =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/images/no-screenshots`);


export const getIp = () =>
  axios.post(
    `${config.API_BASE_URI}${getPort(8080)}/api/getip`
);

export const getKitaInfoByUserId = userId =>
  axios.get(`${config.API_BASE_URI}${getPort(8080)}/api/kitas/kitabyuserid/${userId}`);
