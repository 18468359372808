import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import cn from 'classnames';

import { isAwoWW } from 'library/api/tenantConfig';
import { getSettings } from 'library/api/formEditor';
import { CONTRACT_ONLY_KITAS, AWOMEMBER_LINK } from 'library/common/commonConstants/kitaConstants';
import KitaPanelWrapper from 'library/common/commonComponents/KitaPanelWrapper';
import Wrapper from 'library/common/commonComponents/Wrapper';
import TextCaret from 'library/common/commonComponents/TextCaret';
import { getGoogleTranslatorModuleEnabled } from 'library/common/commonSelectors/kitaSelectors';
import { store } from 'main/store/configureStore';
import { useDropdown } from 'library/common/commonHooks/useDropdown';
import DropdownSpacesSearch from './bottombarFrames/DropdownSpacesSearch/DropdownSpacesSearchContainer';
import BottombarNavItem from './bottombarFrames/bottombarNavItem';
import BottombarGroupLogo from './bottombarFrames/bottombarGroupLogo';
import BottombarHamburger from './bottombarFrames/bottombarHamburger';
import InstitutionSearch from 'library/common/commonComponents/InstitutionSearch';
import useTranslatedLinks from './hooks/useTranslatedLinks';

import styles from './bottombar.module.scss';
import { isPublicUser } from 'library/utilities/user';

export function Bottombar({
  modules,
  letter,
  backgroundColor,
  groupId,
  groupInfo,
  googleTranslatorActive,
  kitaId,
  isVirtualKita,
  kitaAdmin,
  employee,
  formEditorSettingsChanged,
  kitaName,
  kitaDescription,
  userEmail,
}) {
  const { kitas } = store.getState().kitaReducer;
  const user = useSelector(state => state.userReducer);
  const [isBounceAnimation, setIsBounceAnimation] = useState('new group');
  const { t } = useTranslation();
  const [hasDefaultContract, setHasDefaultContract] = useState(false);
  const [allowParentsFillOut, setAllowParentsFillOut] = useState(false);

  const links = useTranslatedLinks(
    modules,
    googleTranslatorActive,
    kitaId,
    kitaAdmin,
    employee,
    isVirtualKita,
    hasDefaultContract,
    allowParentsFillOut,
    kitaName,
    kitaDescription,
    user,
    userEmail,
  );
  const calendarLink = links.find(x => x.to.includes('calendar'));
  const dashboardLink = links.find(x => x.to.includes('dashboard'));
  const isAWO = isAwoWW();

  const hamburgerLinks = useMemo(() => {
    let hLinks = links.filter(x => !x.to.includes('calendar'));

    if ((window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ||
      window.location.hostname.toLowerCase().includes('dev3.safe2connect.org') ||
      window.location.hostname.toLowerCase().includes('staging3.safe2connect.org'))) {
      hLinks = hLinks.filter(
        x => !x.to.includes('dashboard'))
    }

    if (isPublicUser(user)) {
      if (isAWO) {
        const newLink = {
          faIcon: 'fa-person',
          title: t('Header.AWO-Member'),
          to: AWOMEMBER_LINK,
        };
        hLinks.push(newLink);
      }
    }

    if (isAWO) {
      const newLink = {
        title: t('Header.AWO-vor Ort'),
        to: '/articles/overview',
      };
      hLinks.push(newLink);
    }
    return hLinks;
  }, [links, user, isAWO, t]);

  const {
    isOpened: isSpacesSearchOpenedPanel,
    closeDropdown: closeDropdownPanel,
    DropdownWrapper: DropdownSpacesSearchWrapperPanel,
  } = useDropdown(DropdownSpacesSearch, setIsBounceAnimation);

  const {
    isOpened: isSpacesSearchOpenedNoPanel,
    closeDropdown: closeDropdownNoPanel,
    DropdownWrapper: DropdownSpacesSearchWrapperNoPanel,
  } = useDropdown(DropdownSpacesSearch, setIsBounceAnimation);

  useEffect(() => {
    return () => {
      setIsBounceAnimation(true);
    };
  }, [groupId]);

  useEffect(() => {
    getSettings()
      .then(res => {
        setAllowParentsFillOut(
          res != null && res.data != null && res.data.allowParentsFillOut === true,
        );
        setHasDefaultContract(
          res != null && res.data != null && res.data.defaultContractForm !== null,
        );
      })
      .catch(() => {
        setAllowParentsFillOut(false);
        setHasDefaultContract(false);
      });
  }, [kitaId, formEditorSettingsChanged]);

  function kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription) {
    if (
      (kitaName && typeof kitaName !== 'undefined' && kitaName.includes('Betreuungsverträge')) ||
      (kitaDescription &&
        typeof kitaDescription !== 'undefined' &&
        kitaDescription.includes('Betreuungsverträge'))
    ) {
      return true;
    }
    return false;
  }

  const renderBottomBarWrapper = isPanel => {
    const isActive = isPanel ? isSpacesSearchOpenedPanel : isSpacesSearchOpenedNoPanel;
    const DropdownWrapperPanel = isPanel
      ? DropdownSpacesSearchWrapperPanel
      : DropdownSpacesSearchWrapperNoPanel;

    return (
      <Wrapper>
        <div className={styles.bottombarWrapper}>
          <div className={styles.bottombarWrapperNav}>
            {kitas &&
              kitas.length > 0 &&
              !isVirtualKita &&
              !CONTRACT_ONLY_KITAS.includes(kitaId) &&
              !kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription) && (
                <DropdownWrapperPanel
                  render={dropdown => (
                    <BottombarNavItem
                      isActive={isActive}
                      title={
                        <div className={groupId ? styles.groupNavContainer : ''}>
                          {!groupId ? (
                            t('Header.Bottombar.Menu.my groups')
                          ) : (
                            <div
                              className={cn(styles.avatarContainer, 'animated', {
                                bounceIn: isBounceAnimation,
                              })}
                              style={{ backgroundColor }}
                            >
                              <BottombarGroupLogo groupInfo={groupInfo} letter={letter} />
                            </div>
                          )}
                          <TextCaret />
                          {dropdown}
                        </div>
                      }
                      isGroupPage={groupId}
                      faIcon='fa-users'
                    />
                  )}
                  closeDropdown={isPanel ? closeDropdownPanel : closeDropdownNoPanel}
                  closeOnClick={false}
                />
              )}
            {kitas &&
              kitas.length > 0 &&
              isVirtualKita &&
              isAWO &&
              !CONTRACT_ONLY_KITAS.includes(kitaId) &&
              !kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription) && (
                <DropdownWrapperPanel
                  render={dropdown => (
                    <BottombarNavItem
                      isActive={isActive}
                      title={
                        <div className={groupId ? styles.groupNavContainer : ''}>
                          {!groupId ? (
                            t('Header.Bottombar.Menu.my groups')
                          ) : (
                            <div
                              className={cn(styles.avatarContainer, 'animated', {
                                bounceIn: isBounceAnimation,
                              })}
                              style={{ backgroundColor }}
                            >
                              <BottombarGroupLogo groupInfo={groupInfo} letter={letter} />
                            </div>
                          )}
                          <TextCaret />
                          {dropdown}
                        </div>
                      }
                      isGroupPage={groupId}
                      faIcon='fa-users'
                    />
                  )}
                  closeDropdown={isPanel ? closeDropdownPanel : closeDropdownNoPanel}
                  closeOnClick={false}
                />
              )}
            <div className={isAWO ? styles.awoMobileNav : styles.mobileNav}>
              {calendarLink && !isVirtualKita && (
                <BottombarNavItem
                  withLink
                  to={calendarLink.to}
                  key={calendarLink.to}
                  title={
                    <div>
                      {calendarLink.title}
                      <TextCaret />
                    </div>
                  }
                  faIcon={calendarLink.faIcon}
                  imageIcon={calendarLink.imageIcon}
                />
              )}
              {isVirtualKita && (
                <BottombarNavItem
                  withLink
                  to='/calendar'
                  key='/calendar'
                  title={
                    <div>
                      {t('Header.Bottombar.Menu.calendar')}
                      <TextCaret />
                    </div>
                  }
                  faIcon='fa-calendar'
                />
              )}
            </div>
            {dashboardLink && (window.location.hostname.toLowerCase().includes('indag.safe2connect.org') ||
              window.location.hostname.toLowerCase().includes('dev3.safe2connect.org') ||
              window.location.hostname.toLowerCase().includes('staging3.safe2connect.org')) && (
                <div className={styles.mobileNav}>
                  <BottombarNavItem
                    withLink
                    to={dashboardLink.to}
                    key={dashboardLink.to}
                    faIcon={dashboardLink.faIcon}
                    imageIcon={dashboardLink.imageIcon}
                  />
                </div>
              )}
            {/* <div className={styles.mobileNav}>
                  {awowwInfoLink && (
                    <BottombarNavItem
                      withLink
                      to={awowwInfoLink.to}
                      key={awowwInfoLink.to}
                      title={awowwInfoLink.title}
                      faIcon={awowwInfoLink.faIcon}
                      imageIcon={awowwInfoLink.imageIcon}
                    />
                  )}
                </div> */}
            <div className={isAWO ? styles.awoDektopNav : styles.desktopNav}>
              {user.lastName !== 'Caterer' &&
                links.map(link => (
                  <BottombarNavItem
                    withLink
                    to={link.to}
                    key={link.to}
                    title={link.title}
                    faIcon={link.faIcon}
                    imageIcon={link.imageIcon}
                  />
                ))}
            </div>

            <BottombarHamburger links={hamburgerLinks} />
            {/*isAWO && <InstitutionSearch />*/}
          </div>

          {user.lastName !== 'Caterer' &&
            !CONTRACT_ONLY_KITAS.includes(kitaId) &&
            !kitaOrDescriptionNameIncludesBetreuungsverträge(kitaName, kitaDescription) && (
              <div>{kitaId && <BottombarNavItem withLink to='/search/1' faIcon='fa-search' />}</div>
            )}
        </div>
      </Wrapper>
    );
  };

  return (
    <div className={styles.bottombar}>
      <div className={styles.bottombarPanel}>
        <KitaPanelWrapper>{renderBottomBarWrapper(true)}</KitaPanelWrapper>
      </div>

      <div className={styles.bottombarNoPanel}>{renderBottomBarWrapper(false)}</div>
    </div>
  );
}
export const mapStateToProps = state => ({
  groupId: state.groupReducer.groupId,
  letter: state.groupReducer.letter,
  backgroundColor: state.groupReducer.colorCode,
  groupInfo: state.groupReducer,
  googleTranslatorActive: getGoogleTranslatorModuleEnabled(state),
  formEditorSettingsChanged: state.formEditorReducer.settingsChanged,
});
export default connect(mapStateToProps)(Bottombar);
